import React, {useEffect, useState} from 'react';
import {ReactComponent as FilterIcon} from "assets/images/profile_filter_icon.svg"
import CustomDatePicker from './CustomDatePicker';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {endOfDay, startOfDay} from "date-fns";
import {useSearchParams} from "react-router-dom";
import Button from "./material-kit/Button";
import {useTypedDispatch, useTypedSelector} from "../hooks/useTypedRedux";
import {updateModal} from "../redux/slices/appSlice";

interface FilterDateProps {
    onSave: (from: Date, to: Date) => void,
    date_start?: Date
    date_end?: Date
}


const FilterDate = ({onSave, date_start, date_end}: FilterDateProps): JSX.Element => {
    const [dateStart, setDateStart] = useState<Date | undefined>(date_start || new Date(new Date().setDate(new Date().getDate() - 7)));
    const [dateEnd, setDateEnd] = useState<Date | undefined>(date_end || new Date());
    const {t} = useTranslation();
    const [rangesParams, setRangesParams] = useSearchParams()

    useEffect(() => {
        if (dateStart && dateEnd){
            setRangesParams({range_start: `${+startOfDay(dateStart!)}`, range_end: `${+endOfDay(dateEnd!)}`})
        }
    }, [dateStart, dateEnd])

    const {isOpen, isMobile} = useTypedSelector(state => {
        return {
            isOpen: state.appSlice.modal,
            isMobile: state.appSlice.isMobile
        }
    })
    const dispatch = useTypedDispatch();

    const fields = {
        filter: t(`pages.profile.filter_by_date`),
        from: t(`pages.profile.from`),
        to: t(`pages.profile.to`),
    }

    return (
        <Container>
            <ContainerIcon>
                <div style={{textTransform:'uppercase'}}>
                    {fields.filter}
                </div>

                <FilterIcon/>

            </ContainerIcon>

            <Container style={{marginTop: 0}}>
                <CustomDatePicker id={'profile_history_calendar_from_input'}
                                  label={fields.from}
                                  value={dateStart}
                                  minDate={new Date(new Date(dateEnd || '').getTime() + (3600 * 1000 * 24))}
                                  maxDate={new Date(new Date().getFullYear(), 12, 31)}
                                  onChange={setDateStart}
                />
                <CustomDatePicker id={'profile_history_calendar_to_input'}
                                  label={fields.to}
                                  value={dateEnd}
                                  minDate={new Date(2021, 1, 1)}
                                  maxDate={new Date(new Date(dateStart || '').getTime() - (3600 * 1000 * 24))}
                                  onChange={setDateEnd}
                />
                <CustomButton id={'profile-history-bets-show-button'} onClick={async () => {
                    await onSave(startOfDay(dateStart!), endOfDay(dateEnd!))
                    dispatch(updateModal(true))
                }}>
                    {t(`pages.profile.show`)}
                </CustomButton>
            </Container>

        </Container>
    );
};

export default FilterDate;


const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * + * {
    margin-top: 10px;
  }
`


const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;
  color: ${({theme}) => theme.secondary.disabled};

  & svg > g > path {
    fill: ${({theme}) => theme.secondary.disabled};
  }
`

const CustomButton = styled(Button)`
  width: 150px;
  font-size: 20px;
`
import React, { useRef, useEffect } from 'react';


function createRootElement(id: string) {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
}
// @ts-ignore
function addRootElement(rootElem) {
    document.body.insertBefore(
        rootElem,
        // @ts-ignore
        document.body.lastElementChild.nextElementSibling,
    );
}

function usePortal(id: string) {
    const rootElemRef = useRef<HTMLDivElement>(null);


    useEffect(function setupElement() {
        const existingParent = document.querySelector(`#${id}`);
        const parentElem = existingParent || createRootElement(id);

        if (!existingParent) {
            addRootElement(parentElem);
        }

        parentElem.appendChild(rootElemRef.current!);

        return function removeElement() {

            rootElemRef.current!.remove();
            if (!parentElem.childElementCount) {
                parentElem.remove();
            }
        };
    }, [id]);


    function getRootElem() {
        if (!rootElemRef.current) {
            // @ts-ignore
            rootElemRef.current = document.createElement('div');
        }
        return rootElemRef.current;
    }

    return getRootElem();
}

export default usePortal;
import MainDesktop from "pages/Main.desktop";
import Main from "pages/Main";
import { ReactComponent as HorsesIcon } from "assets/images/navbar/icon_horses.svg";
import { ReactComponent as PersonIcon } from "assets/images/menu_profile/menu_profile_personal_data_icon.svg";
import { ReactComponent as CasinoBetHistoryIcon } from "assets/images/menu_profile/menu_profile_bet_history_icon.svg";
import { ReactComponent as CashBackHistoryIcon } from "assets/images/menu_profile/menu_profile_cashback_history_icon.svg";
import { ReactComponent as HistoryIcon } from "assets/images/menu_profile/menu_profile_operations_history_icon.svg";
import { ReactComponent as ChangePasswordIcon } from "assets/images/menu_profile/menu_profile_change_password_icon.svg";
import { RoutingList } from "../interfaces";

import { ReactComponent as MainIcon } from "assets/images/animated_loading_icon.svg";
import Promotions from "../pages/Promotions";
import PersonalDataDesktop from "../pages/profile/PersonalData.desktop";
import ChangePasswordDesktop from "../pages/profile/ChangePassword.desktop";
import BetHistoryDesktop from "../pages/profile/BetHistory.desktop";
import OperationsHistoryDesktop from "../pages/profile/OperationsHistory.desktop";
import CashbackHistoryDesktop from "../pages/profile/CashbackHistory.desktop";
import PersonalDataMobile from "../pages/profile/PersonalData";
import ChangePasswordMobile from "../pages/profile/ChangePassword";
import OperationsHistoryMobile from "../pages/profile/OperationsHistory";
import BetHistoryMobile from "../pages/profile/BetHistory";
import CashbackHistoryMobile from "../pages/profile/CashbackHistory";
import Horses from "../pages/Horses";
import TvGamesDesktop from "pages/TvGames.desktop/TvGames.desktop";
import GamesDesktop from "../pages/Games.desktop/Games.desktop";
import Games from "../pages/Games/Games";
import TvGames from "../pages/TvGames/TvGames";
import Game from "../pages/Game";
import HallOfFame from "pages/hallOfFame/HallOfFame";
import HallOfFlameMobile from "pages/hallOfFame/HallOfFame.mobile";

const routingLinks: RoutingList[] = [
    { //Порядок обязатльный перед LOGIN_PAGE
        key: 'MAIN',
        lang_key: 'main',
        to: '/',
        component: <MainDesktop />,
        mobileComponent: <Main />,
        isAuth: false,
        iconJsx: <MainIcon />
    },
    {
        key: 'GAMES',
        lang_key: 'games',
        to: '/games',
        component: <GamesDesktop />,
        mobileComponent: <Games />,
        isAuth: false,
        iconJsx: <MainIcon />
    },
    // {
    //     key: 'PROVIDER_GAMES',
    //     lang_key: 'games',
    //     to: '/provider/:providerId/games',
    //     mobileComponent: <Provider/>,
    //     isAuth: false
    // },
    {
        key: 'GAME',
        lang_key: 'game',
        to: '/game/:providerId/:gameId',
        component: <Game />,
        mobileComponent: <Game />,
        isAuth: true
    },
    {
        key: 'TV_GAMES',
        lang_key: 'tv_games',
        to: '/tv-games',
        mobileComponent: <TvGames />,
        component: <TvGamesDesktop />,
        isAuth: false,
        // iconJsx: <TvGamesIcon/>
        iconJsx: <MainIcon />
    },
    {
        key: 'HORSES',
        lang_key: 'horses',
        to: '/horses',
        component: <Horses />,
        mobileComponent: <Horses />,
        isAuth: true,
        iconJsx: <HorsesIcon />
    },
    //Прописываются в desktop и mobile роутах, здесь нужны для получения данных
    {
        key: 'SPORT',
        lang_key: 'sport',
        to: '/sport',
        // component: <Sport type={"sport"} isDesktop/>,
        // mobileComponent: <Sport type={"sport"}/>,
        component: <div />,
        mobileComponent: <div />,
        isAuth: false,
        iconJsx: <MainIcon />
    },
    //Прописываются в desktop и mobile роутах, здесь нужны для получения данных
    {
        key: 'LIVE_SPORT',
        lang_key: 'live',
        to: '/live-sport',
        component: <div />,
        mobileComponent: <div />,
        // component: <Sport type={"live-sport"} isDesktop/>,
        // mobileComponent: <Sport type={"live-sport"}/>,
        isAuth: false,
        iconJsx: <MainIcon />
        // iconJsx: <LiveSportsIcon/>
    },
    {
        key: 'PROMOTIONS',
        lang_key: 'promotions',
        to: '/promotions',
        component: <Promotions isDesktop />,
        mobileComponent: <Promotions />,
        isAuth: false
    },
    {
        key: 'STATIC_PAGES',
        lang_key: 'static_pages',
        to: '/static',
        component: <div />,
        mobileComponent: <div />,
        isAuth: false
    },
    {
        key: 'PROFILE',
        lang_key: 'profile',
        to: '/profile',
        mobileComponent: <div />,
        // mobileComponent: <Profile/>,
        isAuth: true
    },
    {
        key: 'PROFILE_PERSONAL_DATA',
        lang_key: 'personal_data',
        to: '/profile/personal-data',
        mobileComponent: <PersonalDataMobile />,
        component: <PersonalDataDesktop />,
        isAuth: true,
        iconJsx: <PersonIcon />
    },
    {
        key: 'PROFILE_BET_HISTORY',
        lang_key: 'bet_history',
        to: '/profile/bet-history',
        component: <BetHistoryDesktop />,
        mobileComponent: <BetHistoryMobile />,
        isAuth: true,
        iconJsx: <CasinoBetHistoryIcon />
    },
    {
        key: 'PROFILE_CASHBACK_HISTORY',
        lang_key: 'cashback_history',
        to: '/profile/cashback-history',
        component: <CashbackHistoryDesktop />,
        mobileComponent: <CashbackHistoryMobile />,
        isAuth: true,
        iconJsx: <CashBackHistoryIcon />
    },
    {
        key: 'PROFILE_OPERATION_HISTORY',
        lang_key: 'history_of_operations',
        to: '/profile/operation-history',
        component: <OperationsHistoryDesktop />,
        mobileComponent: <OperationsHistoryMobile />,
        isAuth: true,
        iconJsx: <HistoryIcon />
    },
    {
        key: 'PROFILE_SETTINGS',
        lang_key: 'change_password',
        to: '/profile/settings',
        component: <ChangePasswordDesktop />,
        mobileComponent: <ChangePasswordMobile />,
        isAuth: true,
        iconJsx: <ChangePasswordIcon />
    },
    {
        key: 'THE_HALL_OF_FAME',
        lang_key: 'the_hall_of_fame',
        to: '/the-hall-of-fame',
        component: <HallOfFame />,
        mobileComponent: <HallOfFlameMobile />,
        isAuth: false,
    },
    // {
    //     key: 'CRASH',
    //     lang_key: 'crash',
    //     to: '/live-sport',
    //     component: <div/>,
    //     mobileComponent: <div/>,
    //     // component: <Sport type={"live-sport"} isDesktop/>,
    //     // mobileComponent: <Sport type={"live-sport"}/>,
    //     isAuth: false,
    //     iconJsx: <MainIcon/>
    //     // iconJsx: <LiveSportsIcon/>
    // },

]
export default routingLinks

import { useEffect } from "react";
import i18n from "i18n";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useCreateSpSessionMutation } from "redux/api/gamesApi";
import { useLocation } from "react-router-dom";
import useScript from "hooks/useScript";
import Loader from "ui/Loader/Loader";
import settings from "settings";

const Sp = () => {
  const services =
    settings.NODE_ENV === "development"
      ? settings.SETTINGS.SERVICES_DEV
      : settings.SETTINGS.SERVICES_PROD;
  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });
  const location = useLocation();
  const [CreateSpSession, { isLoading }] = useCreateSpSessionMutation();
  const status = useScript(services.SP.API_INTEGRATION);

  const initialize = (token: string, page: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.SP = new window.AltenarSportsbook("#sp", {
      walletCode: services.SP.WALLETCODE,
      lang: i18n.language,
      skinid: services.SP.SKINID,
      page,
      token,
      instance: services.SP.INSTANCE,
      fixedbottom: 70,
    });
  };

  useEffect(() => {
    const mainWrapper = document.getElementById("main-wrapper");

    if (mainWrapper) {
      mainWrapper.style.height = "auto";
    }

    return () => {
      if (mainWrapper) {
        mainWrapper.style.height = "100%";
      }

      const sp = document.getElementById("sp");
      // @ts-ignore
      window.SP.destroy();
      sp!.style.display = "none";
    };
  }, [location.pathname]);

  useEffect(() => {
    if (status === "ready") {
      if (user) {
        CreateSpSession()
          .then((payload) => {
            if ("data" in payload && "sessionUrl" in payload.data) {
              if (location.pathname.includes("/sport")) {
                initialize(payload.data.sessionUrl.gameUrl, "prelive");
              } else {
                initialize(payload.data.sessionUrl.gameUrl, "live");
              }
            }
          })
          .catch((err) => {
            console.log(`err := `, err);
          });
      } else {
        if (location.pathname.includes("/sport")) {
          initialize("", "prelive");
        } else {
          initialize("", "live");
        }
      }
    }
  }, [user, location.pathname, status, CreateSpSession]);

  return status === "loading" || isLoading ? <Loader /> : null;
};

export default Sp;

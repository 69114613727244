import {regularExpression} from "./regExp";

export const validateExpression = {
    withoutValidation: [],
    empty: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
    ],
    login: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isLogin, message: "messages.errors.from_2_to_50"},
        {regExp: regularExpression.isWithoutAnySpace, message: "messages.errors.without_space"},
        //{regExp: regularExpression.isUsernameIncorrectSymbols, message: "messages.errors.incorrect_username"},
    ],
    email: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isEmail, message: "messages.errors.incorrect_email"},
    ],
    phone: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isPhone, message: "messages.errors.incorrect_phone"},
    ],
    loginSearch_unstrict: [
        {regExp: regularExpression.isLoginSearch, message: "messages.errors.incorrect_username"},
    ],
    id_unstrict: [
        {regExp: regularExpression.isNumberWithoutZero, message: "messages.errors.from_1_to_1m"},
    ],
    float_unstrict: [
        {regExp: regularExpression.isAmount, message: "messages.errors.from_1_to_1m"},
    ],
    password: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isPasswordCheck, message: "messages.errors.from_2_to_50"},
        {regExp: regularExpression.isWithoutAnySpace, message: "messages.errors.space_forbidden"},
        //{regExp: regularExpression.isPasswordIncorrectSymbols, message: "messages.errors.incorrect_password"},
    ],
    password_unstrict: [
        {regExp: regularExpression.isPassword, message: "messages.errors.from_6_to_12"},
        {regExp: regularExpression.isWithoutAnySpace, message: "messages.errors.space_forbidden"},
    ],
    title: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isTitle, message: "messages.errors.from_2_to_255"},
    ],
    urlShort: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isUrlShortCheck, message: "messages.errors.incorrect_url_page_example"},
    ],
    url: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isUrlCheck, message: "messages.errors.incorrect_url_advert_example"},
    ],
    _url: [
        {regExp: regularExpression.isUrl, message: "messages.errors.incorrect_url_page_example"},
    ],
    lang: [
        {regExp: /\w+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isLetterString, message: "messages.errors.incorrect_value"},
    ],
    priority: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isNumberWithZero, message: "messages.errors.from_1_to_1m"},
    ],
    int: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isNumberWithZero, message: "messages.errors.from_1_to_1m"},
    ],
    intWithoutZero: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isNumberWithoutZeroCheck, message: "0 is forbidden"},
        {regExp: regularExpression.isNumberWithZero, message: "messages.errors.from_1_to_1m"},
    ],
    float: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isAmount, message: "messages.errors.from_1_to_1m"},
    ],
    voucher_int: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
    ],
    maxBalance: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isNumberWithoutZero, message: "messages.errors.from_1_to_1m"},
    ],
    wagering: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isWagering, message: "messages.errors.from_0_to_20"},
    ],
    categoryName: [
        {regExp: /.+$/, message: "messages.errors.field_is_empty"},
        {regExp: regularExpression.isCategory, message: "messages.errors.from_2_to_50"},
    ]
}

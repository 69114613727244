import React, {useEffect, useState} from "react";

export interface IInput {
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement> | string) => void,
    errorText: string,
    setErrorText: React.Dispatch<React.SetStateAction<string>>,
    successText: string,
    setSuccessText: React.Dispatch<React.SetStateAction<string>>,
    test: () => boolean
}


const useInput = (initialValue: string, validation?: string) => {
    const [value, setValue] = useState(initialValue)

    const [errorText, setErrorText] = useState('')
    const [successText, setSuccessText] = useState('')


    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
        if (typeof e === 'string') {
            setValue(e)
        } else {
            setValue(e.target.value)
        }
    }

    const test = (): boolean => {
        let res = true;
        if (validation) {
            res = new RegExp(validation, 'g').test(value)
        }
        return res
    }

    return {
        value, onChange, errorText, setErrorText, test, successText, setSuccessText
    }
}

export default useInput

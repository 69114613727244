import {BASE_URL, BaseQueryInterceptor, commonAPI} from './commonApi';

import {
    ICheckRecoverySession,
    ICode,
    IConfirmNumber, ICreateRecoverySession, ICreateVerification, IResetPassword,
    ISendCode, IStatusRecoveryResponse,
    IUpdateMyPassword, IUpdateUserInfo,
    IUser,
    IUserBalance,
    IUserLogin,
    IUserState, IVerification
} from "redux/types/userTypes";

import {createApi} from "@reduxjs/toolkit/query/react";

export const userAPI = createApi({
    reducerPath: 'userApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({
        loginMe: build.mutation<IUser, IUserLogin>({
            query: (obj) => ({
                url: '/client-login',
                method: 'POST',
                body: obj.data,
            }),
        }),
        logoutMe: build.mutation<string, void>({
            query: () => ({
                url: "/logout",
                method: 'DELETE'
            }),
        }),
        getUserData: build.query<IUser, void>({
            query: () => ({
                url: '/auth/me',
                method: 'GET',
            }),
        }),
        getUserBalance: build.mutation<IUserBalance, void>({
            query: () => ({
                url: '/info/balance',
                method: 'GET',
            })
        }),
        updateMyPassword: build.mutation<string, IUpdateMyPassword>({
            query: (obj) => ({
                url: "/me/password",
                method: 'PUT',
                body: obj.data,
            }),
        }),
        sendCode: build.mutation<ICode, ISendCode>({
            query: (obj) => ({
                url: "/signup/send-code",
                method: 'POST',
                body: obj,
            }),
        }),
        confirmCode: build.mutation<string, IConfirmNumber>({
            query: (obj) => ({
                url: "/signup/confirm-number",
                method: 'PATCH',
                body: obj,
            }),
        }),
        createRecoverySession: build.mutation<IStatusRecoveryResponse, ICreateRecoverySession>({
            query: (obj) => ({
                url: "/password-recovery",
                method: 'POST',
                body: obj,
            }),
        }),
        recoveryPassword: build.mutation<string, IResetPassword>({
            query: (obj) => ({
                url: "/password-recovery",
                method: 'PATCH',
                body: obj,
            }),
        }),
        checkRecoverySession: build.mutation<IStatusRecoveryResponse, ICheckRecoverySession>({
            query: (obj) => ({
                url: `/password-recovery/${obj.id}`,
                method: 'GET'
            }),
        }),
        updateUserData: build.mutation<IUser, IUpdateUserInfo>({
            query: (obj) => ({
                url: `/my/full-data`,
                method: 'PATCH',
                body: obj
            }),
        }),
        getFullUserData: build.mutation<IUser, void>({
            query: (obj) => ({
                url: `/my/full-data`,
                method: 'GET'
            }),
        }),
        createVerification: build.mutation<IVerification, ICreateVerification>({
            query: (obj) => ({
                url: `/verifications`,
                method: 'POST'
            }),
        }),
        getVerificationStatus: build.query<IVerification, void>({
            query: (obj) => ({
                url: `/my-verifications-status`,
                method: 'GET'
            }),
        })
    })
});

export const {
    useLoginMeMutation,
    useLogoutMeMutation,
    useGetUserDataQuery,
    useGetUserBalanceMutation,
    useUpdateMyPasswordMutation,
    useSendCodeMutation,
    useConfirmCodeMutation,
    useCheckRecoverySessionMutation,
    useRecoveryPasswordMutation,
    useCreateRecoverySessionMutation,
    useUpdateUserDataMutation,
    useGetFullUserDataMutation,
    useCreateVerificationMutation,
    useGetVerificationStatusQuery
} = userAPI
import React from "react";
import styled from "styled-components"

interface IFooterColumnContainer {
    children?: React.ReactNode,
    title?: string
}

const FooterColumnContainer = (props: IFooterColumnContainer): JSX.Element => {
    const {children, title} = props;
    return (
        <FlexContainer>
            <Title>
                {`${title}`}
            </Title>
            {children}
        </FlexContainer>

    )
}

export default FooterColumnContainer;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  margin: 40px 0 24px;
  font-size: 24px;
  text-transform: uppercase;
  color: ${({theme}) => theme.text.white}
`


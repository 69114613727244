import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import {getStorage} from 'hooks/useStorage';
import {useTranslation} from "react-i18next";
import IconButton from "./material-kit/IconButton";
import {ReactComponent as PersonLogo} from "../assets/images/header_user-profile_icon.svg";
import {ReactComponent as ArrowDrop} from "../assets/images/header_drop-down-button_icon.svg";

import SignInModal from '../components/modals/SignInModal';
import {useTypedSelector} from "../hooks/useTypedRedux";
import ProfileModal from 'components/modals/ProfileModal';
import useOutsideClick from "../hooks/useOutsideClick";

const DesktopProfileMenu = () => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const {user} = useTypedSelector(state => {
        return {
            user: state.userSlice.user,
        }
    })

    const [isOpen, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setOpen(false)
    }, [window.location.pathname])

    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        if (user) setOpen(false)
    })

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen(!isOpen)
    }


    return (
        <>
            {user
                ? <Wrapper ref={ref}>
                    <StyledButton style={{width: 81}} color={'secondary'} onClick={handleClick} id={'navbar-button-open-profile-login'}>
                        <>
                            <PersonIcon isOpen={isOpen}/>
                            <ArrowDrop/>
                        </>
                    </StyledButton>
                    {isOpen && user && <ProfileModal
                        login={user.login} id={user.id} isOpen={isOpen} onClose={handleClose} isAbsolute={true}/>}
                </Wrapper>
                : <Wrapper>
                    <StyledButton color={'secondary'} onClick={handleClick} id={'navbar-button-open-profile-login'}>
                        <span style={{margin: "0 16px"}}>{t(`common.header.login`)}</span>
                    </StyledButton>
                    {isOpen && !user && <SignInModal isOpen={isOpen} onClose={handleClose}/>}
                </Wrapper>
            }
        </>
    );
};

export default memo(DesktopProfileMenu);

const Wrapper = styled.div`
  position: relative;
  z-index: 10000;
`

const PersonIcon = styled(PersonLogo)<{ isOpen?: boolean }>`
  margin-right: 10px;

  & > path {
    fill: ${({theme, isOpen}) => isOpen ? theme.primary.light : {}};
  }
`

const StyledButton = styled(IconButton)`
  white-space: nowrap;
  height: 42px;
  background: ${({theme}) => theme.secondary.main};
  border-radius: 5px;
`

import React, { useCallback, useEffect, useState } from "react";
import styled, {css} from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import {
  selectHallOfFame,
  selectMonth,
} from "../../redux/selectors/hallOfFameSelectors";
import {
  useGetHallOfFameInfoMutation,
  useGetMonthMutation,
} from "redux/api/hallOfFameApi";
import formatDate from "helpers/formatDate";
import i18n from "i18n";
import BackgroundIntro from "../../assets/images/bg_hall_of_fame.png";
import BackgroundItem from "../../assets/images/bg_hall_of_fame_item_mobile.webp";
import { device } from "../../constants/deviceSizes";
import { useCustomToast } from "hooks/useCustomToast";
import { ContainerHallOfFame } from "ui/containers";
import {
  CircleImageStyles,
  ContentItemStyles,
  IntroInfoStyles,
  IntroWrapperStyles,
  ItemGameStyles,
  ItemListOfWinnersStyles,
  ItemNameStyles,
  ItemNumberStyles,
  ItemValueStyles,
  ListOfWinnersStyles,
  MonthItemStyles,
  MonthStyles,
  MonthsListStyles,
  NicknameStyles,
  TitlePageStyles,
} from "./HallOfFameComponents";
import { useNavigate } from "react-router-dom";

const List = React.memo(() => {
  const {t, i18n} = useTranslation();
  const infoHall = useTypedSelector(selectHallOfFame);
  const user = useTypedSelector((state) => state.userSlice.user);
  const navigate = useNavigate();

  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });

  const handlePlayGame = useCallback((sectionID: string, id: string) => {
    if (!user) {
      callToast();
    } else {
      sessionStorage.setItem("previousPage", window.location.href);

      if (sectionID === "planetwinSport") {
        navigate("/sport");
      } else {
        navigate(`/game/${sectionID}/${id}`);
      }
    }
  }, []);

  const isRightDirection = i18n.dir() === 'rtl';

  return (
    <ListOfWinners>
      {infoHall.map((item, i: number) => (
        <ItemListOfWinners  isRight={isRightDirection}  key={item.login + i} bg={BackgroundItem}>
          <ContentItem  isRight={isRightDirection} >
            <ItemNumber>{i + 1}</ItemNumber>
            <ItemName>
              <Nickname>{item.login}</Nickname>
              <ItemGame
                  isRight={isRightDirection}
                  onClick={() =>
                  handlePlayGame(item.game.SectionId, item.game.Id)
                }
              >
                <p>{item.game.Name}</p>
                <CircleImage src={item.game.Thumbnail} />
              </ItemGame>
            </ItemName>
            <ItemValue>
              {item.value} {item.currency}
            </ItemValue>
          </ContentItem>
        </ItemListOfWinners>
      ))}
    </ListOfWinners>
  );
});

const HallOfFlameMobile = () => {
  const infoMonth = useTypedSelector(selectMonth);
  const [showMonth, setShowMonth] = useState<boolean>(false);
  const [getInfoHall] = useGetHallOfFameInfoMutation();
  const [getMonth] = useGetMonthMutation();
  const { t, i18n } = useTranslation();
  const [thisMonth, setThisMonth] = useState<string>("");

  const handleChangeMonth = (month: string) => {
    if (month === thisMonth) {
      return;
    }
    setThisMonth(month);
    getInfoHall({ month });
    setShowMonth(false);
  };

  const handleClickContainer = () => showMonth && setShowMonth(false);

  useEffect(() => {
    getMonth().then((pld: any) => {
      setThisMonth(pld.data[pld.data.length - 1]);
      getInfoHall({ month: pld.data[pld.data.length - 1] });
    });
  }, []);

  return (
    <MobileContainer onClick={handleClickContainer}>
      <IntroInfo bg={BackgroundIntro}>
        <IntroWrapper>
          <TitlePage>{t("pages.hall_of_fame.title")}</TitlePage>
          <Month>
            <p onClick={() => setShowMonth(!showMonth)}>
              {thisMonth
                ? `${formatDate({
                    date: new Date(thisMonth),
                    dateFormat: "MMMM",
                    lang: i18n.language,
                  })}`
                : ""}
            </p>
            {showMonth && (
              <MonthsList>
                {infoMonth.map((month) => (
                  <MonthItem
                    key={month}
                    onClick={() => handleChangeMonth(month)}
                  >
                    {`${formatDate({
                      date: new Date(month),
                      dateFormat: "MMMM",
                      lang: i18n.language,
                    })}`}
                  </MonthItem>
                ))}
              </MonthsList>
            )}
          </Month>
        </IntroWrapper>
      </IntroInfo>
      <List />
    </MobileContainer>
  );
};

export default HallOfFlameMobile;

const MobileContainer = styled(ContainerHallOfFame)``;

const ListOfWinners = styled(ListOfWinnersStyles)`
  max-width: 330px;
`;

const ItemListOfWinners = styled(ItemListOfWinnersStyles)<{ isRight: boolean }>`
  height: 33px;
  background-size: contain;
  transform: ${({isRight}) => isRight ? 'scaleX(-1)' : 'scaleX(1)'};
`;

const ContentItem = styled(ContentItemStyles)<{ isRight: boolean }>`
  padding: 8px 6px;
  ${({isRight}) => isRight && css`
    transform: scaleX(-1);
    padding: 6px 8px;
  `};
`;

const ItemName = styled(ItemNameStyles)`
  font-size: ${({ theme }) => theme.hall_of_fame.font_size_mobile_primary};
  width: 70%;
  span {
    width: 50px;
  }
`;

const Nickname = styled(NicknameStyles)`
  direction: ltr;
`;

const ItemGame = styled(ItemGameStyles)<{ isRight: boolean }>`
  justify-content: space-between;
  min-width: 108px;
  margin-right: 20%;
  margin-left: 15px;

  ${({isRight}) => isRight && css`
      margin-right: 15px;
      margin-left: 20%;
  `};
  
  p {
    direction: ltr;
    font-size: 12px;
    width: 82px;
    text-align: right;
    margin-right: 4px;

    ${({isRight}) => isRight && css`
      margin-right: 0;
      margin-left: 4px;
      text-align: left;
  `};
  }
`;

const ItemNumber = styled(ItemNumberStyles)`
  width: 33px;
  margin: 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.hall_of_fame.font_size_mobile_primary};
`;

const ItemValue = styled(ItemValueStyles)`
  font-size: ${({ theme }) => theme.hall_of_fame.font_size_mobile_primary};
  width: 58px;
`;

const IntroInfo = styled(IntroInfoStyles)`
  margin-bottom: -22px;
  height: 180px;
  background-size: contain;
  background-position: center top;

  @media (${device.laptopL}) {
    height: 160px;
  }
`;

const IntroWrapper = styled(IntroWrapperStyles)``;

const TitlePage = styled(TitlePageStyles)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.hall_of_fame.font_size_mobile_primary};
  position: relative;
  transform: inherit;
  width: 43px;
  margin-top: -20px;

  @media (${device.laptopL}) {
    font-size: 11px;
    line-height: 11px;
  }
`;

const Month = styled(MonthStyles)`
  font-size: ${({ theme }) => theme.hall_of_fame.font_size_mobile_primary};
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const MonthsList = styled(MonthsListStyles)`
  padding: 5px 3px;
  row-gap: 3px;
  column-gap: 2px;
  position: absolute;
  top: 20px;
  left: 0;
  transform: translateX(-37%);
  width: max-content;
`;

const MonthItem = styled(MonthItemStyles)`
  display: flex;
  align-items: center;
  width: 68px;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.hall_of_fame.font_size_mobile_primary};
  line-height: 12px;

  &:active {
    background: ${({ theme }) => theme.hall_of_fame.background_hover};
  }
`;

const CircleImage = styled(CircleImageStyles)`
  width: 27px;
  height: 27px;
`;

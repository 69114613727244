import React, {useState} from 'react';
import {ChangePasswordInfo} from "../../components/profile/ChangePasswordWrapper";
import DesktopWrapper from "../../components/profile/DesktopWrapper";



const ChangePasswordDesktop = (): JSX.Element => {
    return (
        <DesktopWrapper
            title={'PROFILE_SETTINGS'}
            childrenInfo={<ChangePasswordInfo/>}
        />
    );
};

export default ChangePasswordDesktop;

import React from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {ReactComponent as Icon} from "assets/images/main_logo_izibet_icon.svg";
import Button from "ui/material-kit/Button";
import {ITheme} from "interfaces/ITheme";
import useMediaQuery from "hooks/useMediaQuery";
import {device} from "constants/deviceSizes";

const ErrorBoundaryPage = () => {

    const isMobile = useMediaQuery(device.mobileXL)
    return (
        <Wrapper>
            <GlobalStyle/>
            <Content style={{paddingTop: isMobile ? 71 : 119, height: isMobile ? 396 : 484}}>
                <Text style={{margin: isMobile ? '44px 0' : '80px 0 52px'}}>
                    <div>Error</div>
                    <div>Please return back to the page</div>
                </Text>
                <Button onClick={() => window.location.reload()} id={'err'} style={{width: '153px'}}>Reload</Button>
            </Content>
        </Wrapper>
    );
};

export default ErrorBoundaryPage;

const GlobalStyle = createGlobalStyle<{ theme: ITheme }>`
  body {
    background-color: ${({theme}) => theme.background.main};
    height: 100vh;
    width: 100vw;
  }
`;


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const Content = styled.div`
  margin: 0 13px;
  box-sizing: border-box;
  max-width: 642px;
  max-height: 484px;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
`

const Logo = styled(Icon)`
  width: 303px;
  height: 65px;
`

const Text = styled.div`
  padding: 0 30px;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
`

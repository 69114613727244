import React, { useState } from 'react';
import { ChangePasswordInfo } from "../../components/profile/ChangePasswordWrapper";
import MobileWrapper from "../../components/profile/MobileWrapper";

const ChangePasswordMobile = (): JSX.Element => {
    return (
        <MobileWrapper
            title={'PROFILE_SETTINGS'}
            childrenInfo={<ChangePasswordInfo />}
        />
    );
};

export default ChangePasswordMobile;

import { BaseQueryInterceptor, commonAPI } from './commonApi';
import { IBanner, IAppState, IJackpot, ILanguage, IPromotion, IStaticPage } from "../types/appTypes";
import { createApi } from "@reduxjs/toolkit/query/react";

export const appAPI = createApi({
    reducerPath: 'appApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({
        getStaticPage: build.mutation<IStaticPage, number>({
            query: (id) => ({
                url: `/pages/client/${id}`,
                method: 'GET',
            }),
        }),
        getStaticPages: build.mutation<IStaticPage[], void>({
            query: () => ({
                url: `/pages/client`,
                method: 'GET',
            }),
        }),
        getAllPromotions: build.mutation<IPromotion[], { lang: string }>({
            query: (obj) => ({
                url: `/promotions`,
                method: 'GET',
                params: obj,
            }),
        }),
        getAllBanners: build.mutation<IBanner[], { lang: string }>({
            query: (obj) => ({
                url: `/banners`,
                method: 'GET',
                params: obj,
            }),
        }),
        getJackpot: build.mutation<IJackpot[], void>({
            query: () => ({
                url: `/info/jackpot`,
                method: 'GET',
            })
        }),
        getLanguages: build.query<ILanguage[], void>({
            query: () => ({
                url: `/languages`,
                method: 'GET',
            })
        })
    })
})
    ;

export const {
    useGetStaticPageMutation, useGetStaticPagesMutation, useGetJackpotMutation,
    useGetLanguagesQuery, useGetAllBannersMutation, useGetAllPromotionsMutation,
} = appAPI

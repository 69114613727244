import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICreateVerification, IUserState } from "redux/types/userTypes";
import { userAPI } from "../api/userApi";
import settings from "settings";
import axios from "axios";
import { BASE_URL } from "../api/commonApi";
import { putStorage } from "hooks/useStorage";

const initialState: IUserState = {
    user: null,
    verification: {
        status: 'none',
        message: '',
        rejectReason: ''
    }
}

export const createVerificationImages = createAsyncThunk(
    'adminApi/createVerificationImages',
    async (obj: FormData) => {
        const response = await axios({
            url: `${BASE_URL}/api/verifications`,
            method: 'POST',
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: obj
        })
            .then((pld) => {
                return {
                    type: 'adminApi/createVerificationImages',
                    method: 'POST',
                    status: pld.status,
                }
            })
            .catch((pld) => {
                return {
                    type: 'adminApi/createVerificationImages',
                    method: 'POST',
                    status: 'rejected',
                    data: { message: pld?.response?.data?.message }
                }
            })
        return response
    }
)


const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetAdmin(state) {
            state.user = null
        },
        updateBalance(state, { payload }) {
            if (state.user) {
                state.user.balance = payload
            }
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(createVerificationImages.fulfilled, (state: any, action) => {
                state.verification.status = 'created'
            })
            .addMatcher(
                userAPI.endpoints.loginMe.matchFulfilled,
                (state, { payload }) => {
                    window.location.reload();
                    state.user = payload
                    putStorage("ACCESS", settings.SETTINGS.ACCESS, { silent: true });
                })
            .addMatcher(
                userAPI.endpoints.getUserData.matchFulfilled,
                (state, { payload }) => {
                    state.user = payload
                    putStorage("ACCESS", settings.SETTINGS.ACCESS, { silent: true });
                })
            .addMatcher(
                userAPI.endpoints.getUserBalance.matchFulfilled,
                (state, { payload }) => {
                    if (state.user)
                        state.user.balance = payload.data.balance
                })
            .addMatcher(
                userAPI.endpoints.logoutMe.matchFulfilled,
                (state) => {
                    state.user = null
                })
            .addMatcher(
                userAPI.endpoints.updateUserData.matchFulfilled,
                (state, { payload }) => {
                    state.user = payload
                })
            .addMatcher(
                userAPI.endpoints.getFullUserData.matchFulfilled,
                (state, { payload }) => {
                    state.user = payload
                })
            .addMatcher(
                userAPI.endpoints.createVerification.matchFulfilled,
                (state, { payload }) => {
                    state.verification.status = payload.status
                })
            .addMatcher(
                userAPI.endpoints.getVerificationStatus.matchFulfilled,
                (state, { payload }) => {
                    state.verification.status = payload.status || ''
                    state.verification.message = payload.message || ''
                    state.verification.rejectReason = payload.rejectReason || ''
                })
            .addDefaultCase((state, action) => {
            })

    })
})

export const { resetAdmin, updateBalance } = userSlice.actions

export default userSlice.reducer


import {ProvidersImages, TvProvidersImages} from "./providersImages";

type ProviderImageProps = {
    provider: string,
    isTvProvider?: boolean
}
const getProviderImage = (props: ProviderImageProps) => {

    const Obj =
        !props.isTvProvider
            ? ProvidersImages.find(item => item.name.toLowerCase() === props.provider.toLowerCase())
            : TvProvidersImages.find(item => item.name.toLowerCase() === props.provider.toLowerCase())
    if (!Obj) {
        return undefined
    }
    try {
        return require(`../../assets/images/providers/${Obj.image}`)
    } catch (e) {
        return undefined
    }

}


export default getProviderImage;

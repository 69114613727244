import React from "react";
import DesktopWrapper from "../../components/profile/DesktopWrapper";
import {
  ProfileHistoryInfo,
  ProfileHistoryResult,
} from "../../components/profile/ProfileHistoryWrapper";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import { useGetBetsMutation } from "../../redux/api/transactionApi";
import HistoryItem from "../../ui/HistoryItem";
import { useSearchParams } from "react-router-dom";

const BetHistoryDesktop = (): JSX.Element => {
  const [getBets, { isLoading }] = useGetBetsMutation();
  const { bets, betsCount } = useTypedSelector((state) => {
    return {
      bets: state.transactionSlice.bets,
      betsCount: state.transactionSlice.betsCount,
    };
  });

  const [rangesParams, setRangesParams] = useSearchParams();
  const callback = (props: { page?: number; action?: "add" }) =>
    getBets({
      range_start: Number(rangesParams.get("range_start")),
      range_end: Number(rangesParams.get("range_end")),
      page: props.page || undefined,
      action: props.action || undefined,
    });

  return (
    <DesktopWrapper
      title={"PROFILE_BET_HISTORY"}
      count={betsCount}
      childrenInfo={<ProfileHistoryInfo action={callback} />}
      childrenResult={
        <ProfileHistoryResult
          action={callback}
          isLoading={isLoading}
          operationCount={betsCount}
          operationArray={bets}
        >
          {bets.map((item, index) => (
            <React.Fragment key={`bet-history-${index}-${item.createdAt}`}>
              <HistoryItem
                provider={item.providerId}
                gameId={item.gameId}
                amount={`${item.amount.toFixed(2)}`}
                status={item.type}
                date={item.createdAt}
              />
            </React.Fragment>
          ))}
        </ProfileHistoryResult>
      }
    />
  );
};

export default BetHistoryDesktop;

import React from 'react';
import { ReactComponent as CloseIcon } from "assets/images/header_drop-down-button_icon.svg";
import styled from "styled-components";

type SelectButtonProps = {
  onClick: () => void,
  title: string,
  isImage?: boolean,
  isSelect?: boolean,
  img?: string,
  jsx?: JSX.Element

}
const SelectButton = (props: SelectButtonProps) => {
  return (
    <CustomButton id={`select-button`} isSelect={props.isSelect} onClick={() => {
      props.onClick();
    }}>
      <Title>
        {`${props.title}`}
      </Title>
      {
        !props.isImage || props.isSelect ? null :
          props.jsx ? <SvgImg>{props.jsx}</SvgImg> :
            <img style={{ height: 20, width: 20 }} src={props.img} alt={`category-${props.title.slice(0, 2)}`} />

      }
      {
        !props.isSelect ? null :
          <SvgImg isWhite>
            <CloseIcon style={{ width: 15, height: 15 }} />
          </SvgImg>

      }
    </CustomButton>
  );
};

export default SelectButton;


const CustomButton = styled.div<{ isSelect?: boolean }>`
  background: ${({ theme }) => theme.primary.light};
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  height: 29px;
  text-overflow: ellipsis;
  padding: 4px 10px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  
  :hover {
    background:${({ theme }) => theme.primary.light};
  }

  :active {
    background: ${({ theme }) => theme.primary.light};
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  max-width: 210px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
`

const SvgImg = styled.div<{ isWhite?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 20px;
    height: 20px;
  }

  // & > svg > path {
  //   fill: ${props => props.isWhite && "white"};
  // }
`

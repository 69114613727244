import Portal from "../../services/PortalService";
import useOutsideClick from "../../hooks/useOutsideClick";
import styled, {css, CSSProperties} from "styled-components";
import React from "react";
import IconButton from "./IconButton";

interface ImageIconProps {
    src?: string,
    style?: CSSProperties,
    alt: string,
    onClick?: () => void,
    className?: string
}

const ImageIcon = (props: ImageIconProps) => {
    const {src, alt, style, onClick, className} = props;

    return (
        <>
            <IconButton id={alt} onClick={onClick} className={className}>
                <Img style={style} src={src} alt={alt} className={className}/>
            </IconButton>
        </>
    );
};

export default ImageIcon;


const Img = styled.img`
    : hover {
      filter: brightness(0.9);
    }
`

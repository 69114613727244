import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";

type SEOProps = {
    description: string,
    title: string,
    keywords?: string[],
    siteNameKey?: string,
    pageUrl?: string,
    imgPath?: string,
    favicon?: string
}

const SEO = (props: SEOProps) => {
    const {
        siteNameKey = "izibet",
        description = "",
        title = "",
        keywords = ["casino"],
        favicon,
        imgPath
    } = props

    const seoImage = require(`../../${imgPath}`);

    const setFavicon = (favicon: string) => {
        let faviconElement = document.createElement("link");
        faviconElement.setAttribute("rel", "icon");
        faviconElement.setAttribute("href", `/favicon/${favicon}.ico`);
        document.getElementsByTagName('head')[0].appendChild(faviconElement);
        document.getElementById('id_link_apple_touch_icon')?.setAttribute('href', `/favicon/${favicon}192.png`)
    }

    useEffect(() => {
        document.getElementById('head_link_manifest')?.setAttribute('href', `/manifests/manifest_${siteNameKey}.json`)
        setFavicon(siteNameKey)
    }, []);

    return (
        <Helmet
            title={title}
            titleTemplate={`%s `}
            meta={[
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:site_name`,
                    content: title,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `description`,
                    content: description,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `article:section`,
                    content: description,
                },
                {
                    name: `image`,
                    content: seoImage,
                },
                {
                    property: `og:image`,
                    content: seoImage,
                },
                {
                    property: `twitter:image`,
                    content: seoImage,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `article:author`,
                    content: 'Betskilla',
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: 'Betskilla',
                },
            ].concat(
                keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                    }
                    : []
            )}
        >
            <link rel="icon" href={`/${favicon}`}/>
        </Helmet>
    );
};

export default SEO;

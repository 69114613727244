export const regularExpression = {
    //For validation
    isWithoutSpace: /^\w+$|^$/,
    isSpace: /[^\w]+/,
    isEmpty: /\w+$/,
    isWithoutAnySpace: /^\S+$/,

    isTitle: /^.{2,255}$/,

    isLogin: /^.{2,50}$/,
    isPhone: /^(\+\d{11,13})$/,
    isEmail: /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
    isUsernameIncorrectSymbols: /^[a-zA-Z0-9!#$%&'*+—/=?^_`{|}()«»~№]*$/,
    isPasswordIncorrectSymbols: /^[a-zA-Z0-9!$%&'*+—/=?^_`{|}()«»~]*$/,
    isCategory: /^.{2,50}$/,
    isLoginSearch: /^.{0,180}$/,
    isPassword: /^$|^.{2,180}$/,
    isPasswordCheck: /^.{2,50}$/,

    isNumberWithoutZero: /^(?:[1-9]\d{0,5}|1000000|^$)$/,
    isNumberWithZero: /^(?:\d{0,6}|1000000|^$)$/,
    isAmount: /^(?:\d{1,6}(?:\.\d{1,2})?|1000000(?:\.0{1,2})?|^$)$/,
    isWagering: /^(?:\d+\.\d$|^\d+$|^$)$/,

    isLetterString: /^(?:[a-zA-Z]*|^$)$/,
    isUrl: /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))|^$/,
    isUrlShort: /^(?:[a-zA-Z0-9-_]*|^$)$/,
    isLetterNumberString: /^(?:[a-zA-Z0-9]*|^$)$/,
    isLoginPassword: /^$|^\S{2,180}$/,

    //For disabled
    isNumberWithoutZeroCheck: /^(?:[1-9]\d{0,5}|1000000)$/,
    // /^(?:[1-9][0-9]*)$/,
    isNumberWithZeroCheck: /^(?:\d{1,6}|1000000)$/,
    // /^(?:[0-9]+)$/,
    isAmountCheck: /^(?:[0-9]{1,6}(?:\.\d{1,2})?|1000000(?:\.0{1,2})?)$/,
    ///^(?:[0-9]+\.[0-9]{1,2}$|^[0-9]+$)$/,
    isWageringCheck: /^(?:[0-9]+\.[0-9]$|^[0-9]+$)$/,

    isLetterStringCheck: /^(?:[a-zA-Z]+)$/,
    isUrlCheck: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    isUrlShortCheck: /^(?:[a-zA-Z0-9-_]+)$/,
    isLetterNumberStringCheck: /^(?:[a-zA-Z0-9]+)$/,
    isLoginPasswordCheck: /^\S{2,180}$/,
};

export const regexNumber = {
    int: /[0-9]+/,
    phone: /^[+]{0,1}[0-9]{0,13}$/,
    float: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
    signed_int: /^[+-]?[0-9]+$/,
    signed_float: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
    // long_int: /[^0-9+-e]/,
    // long_float: /[^0-9.+\-e]/,
}
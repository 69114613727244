import styled from "styled-components"
import Lottie from 'react-lottie-player';
import starLottie from '../lotties/star_lottie.json'

const StarLottie = (): JSX.Element => {
    return (
        <Wrapper>
            <Lottie
                loop
                play
                animationData={starLottie}

                style={{width: 400, height: 400}}
            />
        </Wrapper>
    );
};

export default StarLottie;


const Wrapper = styled.div`
  background: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)

`
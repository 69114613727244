import React, {useEffect, useRef} from 'react';
import Portal from "services/PortalService";
import useOutsideClick from "hooks/useOutsideClick";
import styled, {css, CSSProperties} from "styled-components";
import {ReactComponent as ExitIcon} from "assets/images/cross_icon.svg";
import {ReactComponent as BackIcon} from "assets/images/mobile_arrow_back_icon.svg";
import IconButton from "./IconButton";
import {useTranslation} from "react-i18next";

type ModalProps = {
    onClose: () => void,
    isOpen: boolean,
    children: React.ReactNode,
    isFullScreen?: boolean,
    extra?: string,
    title?: string,
    style?: CSSProperties
    withHeader?: boolean,
    backIcon?: boolean
}

const Modal = (props: ModalProps) => {
    const {
        isOpen,
        onClose,
        children,
        isFullScreen = false,
        extra = ``,
        title,
        style,
        withHeader = true,
        backIcon = true
    } = props;

    const {t, i18n} = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        onClose();
    })
    const isRight = i18n.dir() === 'rtl';
    return (
        <>
            {isOpen && <Portal id="modal-root">
                <ModalWrapper extra={extra}>
                    <Container isFullScreen={isFullScreen} ref={ref} style={style}>
                        <div>
                            {isFullScreen && !backIcon
                                ? <BackIconContainer isRight={isRight} id={'modal-back-icon'} onClick={() => onClose()}><BackIcon/></BackIconContainer>
                                : <div/>}
                            {title && <Header isFullScreen={isFullScreen}>
                                {title}
                            </Header>}
                            <ExitIconContainer id={`modal-exit-icon`} onClick={() => {
                                onClose()
                            }}><ExitIcon/></ExitIconContainer>
                        </div>
                        {children}
                    </Container>
                </ModalWrapper>
            </Portal>}
        </>
    );
};

export default Modal;

const Container = styled.div <{ isFullScreen?: boolean, isFooter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.background.main};
  ${({isFullScreen, isFooter}) =>
          !isFullScreen ?
                  css`
                    padding: 42px 0;
                    margin: 0;
                    border-radius: 5px;
                    max-width: 95vw;
                    width: fit-content;
                    height: fit-content;
                    max-height: 95vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                  `
                  : css`
                    margin-top: 44px;
                    overflow: auto;
                    height: calc(100% - 44px);
                    width: 100%;
                    border-radius: 0;
                  `}
`

const ModalWrapper = styled.div <{ extra: string }>`
  padding: 0;
  z-index: 9000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({extra}) => extra}
`

const Header = styled.div <{ isFullScreen?: boolean }>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${({isFullScreen}) => isFullScreen ? '25px 0' : '0 0 42px'};
  font-size: ${({isFullScreen}) => isFullScreen ? '16px' : '24px'};
  justify-items: center;
  text-transform: uppercase;
`

const Icon = styled(IconButton)`
  position: absolute;
  top: 18px;
`

const ExitIconContainer = styled(Icon)`
  inset-inline-end: 18px;
`

const BackIconContainer = styled(Icon)<{isRight: boolean}>`
  inset-inline-start: 18px;
    ${({isRight}) => isRight && css`
      transform: rotateY(180deg);
    `}
`

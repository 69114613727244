import React from 'react';
import IconButton from "ui/material-kit/IconButton";
import {ReactComponent as FacebookIcon} from "assets/images/header_facebook_logo.svg";
import DesktopProfileMenu from "ui/DesktopProfileMenu";
import LanguageController from "./LanguageContainer";

type ProfileContainerProps = {
    isDesktop?: boolean
}

const ProfileContainer = (props: ProfileContainerProps): JSX.Element => {
    return (
        <>
            <IconButton style={{justifySelf: "end"}} id={'facebook'}>
                <FacebookIcon/>
            </IconButton>

            <DesktopProfileMenu/>
            <LanguageController isDesktop={true}/>
        </>
    );
};

export default ProfileContainer;

import React, {useEffect, useState} from 'react';

import {ReactComponent as SearchIcon} from "assets/images/search_icon.svg";
import {useDebounce} from "../../hooks/useDebounce";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {FixedSizeList as List} from "react-window";
import InfiniteLoader from 'react-window-infinite-loader';

import {ReactComponent as CrossIcon} from "assets/images/cross_icon.svg";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import Input from "./Input";
import openGame from "../../helpers/openGame";
import {useGetGameListMutation} from "../../redux/api/gamesApi";

const SmartSearchSelect = () => {
    const [titleGame, setTitleGame] = useState("");
    const [page, setPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const {i18n, t} = useTranslation();
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const [getGameList] = useGetGameListMutation();

    const {
        gameList,
        gameListCount
    } = useTypedSelector((state) => {
        return {
            gameList: state.gamesSlice.gameList.toArray().map(item => {
                return {
                    id: item[1].Id,
                    providerId: item[1].SectionId,
                    name: item[1].Name,
                    url: item[1].Thumbnail,
                    isFavorite: item[1].IsFavorite,
                }
            }),
            gameListCount: state.gamesSlice.gameListCount,
        }
    })

    const isItemLoaded = (index: number) => !!gameList[index];

    const loadMoreItems = (startIndex: number, stopIndex: number) => {
        if (gameList.length === gameListCount) {
            setIsLoading(false)
        }
        let newPage = page + 1;
        setPage(newPage);
        getGameList({
            data: {
                title: debouncedSearchTerm,
                size: 30,
                type: "add",
                page: newPage
            }
        })
    };

    useEffect(() => {
        getGameList({
            data: {
                title: debouncedSearchTerm,
                size: 30,
                type: "update"
            }
        });
        setPage(1);
        setIsLoading(true);

    }, [debouncedSearchTerm]);

    const extraInputWrapper = `
    height: 30px;
    `

    const extraInput = `
    ::placeholder {
        color: white;
    }
    height: 30px;
    width: 200px;
    background: transparent;
    border-color: white;
    font-size: 16px
    `

    const extraIcon = ` 
    & svg > path {
        stroke: white;
    }
    width: 20px;
    height: 20px;
    `


    return (
        <div style={{position: "relative"}}>
            <Input placeholder={`${t(`pages.game.search_game`)}`}
                   inputExtra={extraInput}
                   extra={extraInputWrapper}
                   rightIcon={{
                       icon: titleGame !== "" ? <CrossIcon/> : <SearchIcon/>,
                       extra: extraIcon,
                       onClick: () => {
                           if (titleGame !== "") {
                               setTitleGame("")
                           }
                       }
                   }} value={titleGame} onChange={(e) => {
                setTitleGame(e.target.value)
            }}/>
            {(debouncedSearchTerm !== "" && gameList.length !== 0) &&
                <ContainerSelect>
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={gameListCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({onItemsRendered, ref}) => (
                            <List
                                itemData={gameList}
                                onItemsRendered={onItemsRendered}
                                ref={ref}
                                itemCount={gameList.length}
                                itemSize={30}
                                width={200}
                                height={200}
                            >
                                {Row}
                            </List>
                        )}
                    </InfiniteLoader>
                </ContainerSelect>
            }

        </div>
    );
};


const ContainerSelect = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
`

type RowProps = {
    index: number,
    style: any,
    data: any
}


const Row = (props: RowProps) => {
    const {data, index, style} = props;

    return (
        <SelectLi title={data[index]?.name} onClick={() => {
            openGame({
                providerId: data[index]?.providerId,
                gameId: data[index]?.id,
                isAuth: true
            })
        }} style={style}>
            {data[index]?.name}
        </SelectLi>
    );
};

export default SmartSearchSelect;


const SelectLi = styled.div`
  color: black;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: whitesmoke;
  }
`

import { IHallOfFameState } from "redux/types/hallOfFame";
import { hallOfFameApi } from "../api/hallOfFameApi";
import { createSlice } from "@reduxjs/toolkit";

const initialState: IHallOfFameState = {
    top: [],
    month: []
};

const hallOfFameSlice = createSlice({
    name: "hallOfFame",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                hallOfFameApi.endpoints.getHallOfFameInfo.matchFulfilled,
                (state, { payload }) => {
                    state.top = payload;
                }
            )
            .addMatcher(
                hallOfFameApi.endpoints.getMonth.matchFulfilled,
                (state, { payload }) => {
                    state.month = payload;
                }
            )
    },
});


export default hallOfFameSlice.reducer;

import React, {useCallback, useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components"

import {getStorage} from "../../hooks/useStorage";
import routingLinks from "../../constants/routingLinks";
import ModalProfileItem from "../../ui/ModalProfileItem";
import {NavLink, useNavigate} from "react-router-dom";
import {ReactComponent as LogoutIcon} from "assets/images/menu_profile/menu_profile_logout_icon.svg";
import {ReactComponent as CopyIcon} from "assets/images/copy_icon.svg";
import {useTranslation} from "react-i18next";
import IconButton from "../../ui/material-kit/IconButton";
import {useLogoutMeMutation} from "../../redux/api/userApi";
import {toast} from "react-toastify";

interface IProfileModal {
    isOpen: boolean
    onClose: () => void
    login?: string
    id?: number | string
    isMobile?: boolean
    isAbsolute?: boolean
}

const ProfileModal = (props: IProfileModal): JSX.Element => {
    const {isOpen, onClose, login, id, isMobile, isAbsolute} = props;

    const [logoutMe] = useLogoutMeMutation();
    const {t, i18n} = useTranslation();

    const fields = {
        username: t(`common.placeholder.username`),
        password: t(`common.placeholder.password`),
        sing_in: t(`common.modals.sing_in.sing_in`),
        login_now: t(`common.modals.sing_in.login_now`),
        id_copied: t(`messages.success.id_copied`)
    }

    useEffect(() => {
        function disableTouchScroll(e: any){
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        document.getElementById('root')!.style.overflowY = 'hidden';
        document.getElementById('root')!.addEventListener('touchmove',disableTouchScroll);
        return () => {
            document.getElementById('root')!.style.overflowY = 'auto'
            document.getElementById('root')!.removeEventListener('touchmove',disableTouchScroll);
        }
    }, [])

    const navigate = useNavigate();

    const access = getStorage("APP_ACCESS");
    const objEnt: [string, { VISIBLE: boolean, PERMISSIONS: {} }][] = Object.entries(access);
    const correctAccess = objEnt.reduce((acc: string[], [key, val]) => val.VISIBLE ? [...acc, key] : acc, []);

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(`ID: ${id}`)
            .then(() => toast.success(fields.id_copied))
    }, [])

    return (
        <Wrapper isRight={i18n.dir() === 'rtl'} isMobile={isMobile} isAbsolute={isAbsolute}>
            {!isMobile && isAbsolute &&
                <Header>
                    <Login>{login}</Login>
                    <Id>
                        <div>{t(`common.header.id`)}: {id}</div>
                        <IconButton id={`copy-icon`} onClick={handleCopy}
                                    style={{marginRight: 4}}><CopyIcon/></IconButton>
                    </Id>
                </Header>
            }
            {routingLinks.filter(item => (item.key.includes("PROFILE_")) && correctAccess.includes(item.key)).map((item, index) => (
                <React.Fragment key={`${index}-${item.key}-menu-profile`}>
                    <ModalProfileItem title={t(`common.profile.${item.lang_key}`)} icon={item.iconJsx} to={`${item.to}`}
                                      navigate={() => {
                                          onClose()
                                          navigate(`${item.to}`)
                                      }}/>
                </React.Fragment>
            ))}
            <div/>

            <React.Fragment key={`logout-menu-profile`}>
                <ModalProfileItem title={t(`common.profile.logout`)} icon={<LogoutIcon/>}
                                  navigate={() => {
                                      logoutMe().then(pld => {
                                          navigate(`/`);
                                          window.location.reload();
                                      });
                                  }}/>
            </React.Fragment>
        </Wrapper>
    )
}

export default ProfileModal;

const Wrapper = styled.div<{ isMobile?: boolean, isAbsolute?: boolean, isRight?: boolean}>`
  display: grid;
  grid-gap: 20px;
  z-index: ${({isAbsolute}) => isAbsolute ? 1000000 : 1};
  position: ${({isAbsolute}) => isAbsolute ? 'absolute' : 'static'};
  width: 318px;
  height: fit-content;
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
  right: 0;
  background-color: ${({theme}) => theme.background.main};
  text-transform: uppercase;
  ${({isRight}) => isRight && css`
    right: unset;
    left: 0;
  `}
  ${({isMobile}) =>
          !isMobile ?
                  css`
                    margin: 0;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    max-height: 95vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                    top: 57px;
                  `
                  : css`
                    top: 43px;
                    border-radius: 0;
                  `}
`

const Header = styled.div`
  height: 80px;
  display: grid;
  align-content: space-between;
`
const Login = styled.div`
  color: ${({theme}) => theme.primary.light};
  font-size: 24px;
`

const Id = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: space-between;
`

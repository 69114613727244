import React, {lazy, Suspense, useEffect, useState} from 'react'
import {useGetUserDataQuery} from "redux/api/userApi";
import {useTypedDispatch, useTypedSelector} from "../hooks/useTypedRedux";
import {isMobileTrue, updateAlert} from "redux/slices/appSlice";
import {
    useGetAllBannersMutation, useGetAllPromotionsMutation,
    useGetLanguagesQuery, useGetStaticPagesMutation
} from "redux/api/appApi";
import {io} from "socket.io-client";
import {SocketProvider} from "services/SocketService";
import AppLoader from "../assets/loaders/AppLoader";
import {useTranslation} from "react-i18next";
import settings from "../settings"
import {updateBalance} from "../redux/slices/userSlice";
import {IMessage, IStaticPage} from "../redux/types/appTypes";
import {TawkService} from "../services/TawkService";
import PrettyToast from "../ui/PrettyToast";
import {userIdSelector, userSelector} from "../redux/selectors/userSelector";
import useMediaQuery from "../hooks/useMediaQuery";

const MobileApp = lazy(() => import('./mobile-app'))
const DesktopApp = lazy(() => import('./desktop-app'))

const Router = () => {
    const {t, i18n} = useTranslation();
    const [socket, setSocket] = useState<any>(null);
    const [getAllBanners] = useGetAllBannersMutation();
    const [getAllPromotions] = useGetAllPromotionsMutation();
    const {isLoading, isUninitialized, isFetching} = useGetUserDataQuery();
    const {isLoading: isLoadingLanguages} = useGetLanguagesQuery();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [getStaticPages] = useGetStaticPagesMutation();
    const dispatch = useTypedDispatch()

    const userId = useTypedSelector(userIdSelector)

    useEffect(() => {
        getStaticPages();
        getAllBanners({lang: i18n.language})
        getAllPromotions({lang: i18n.language})
    }, [i18n.language])

    useEffect(() => {
        console.log(typeof window.orientation, 'hehe')
        if (typeof window.orientation !== 'undefined') {
            TawkService.initialize();
            setIsMobileDevice(true);
            dispatch(isMobileTrue())
        }
    }, [])

    const {SETTINGS} = settings;
    const mirror = Object.keys(SETTINGS.MIRRORS).find(item => item === window.location.host);
    const BASE_URL = settings.NODE_ENV === "development"
        ? SETTINGS.API_DEV_URL
        : mirror
            ? SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL
            : SETTINGS.API_PROD_URL;

    //@ts-ignore
    useEffect(() => {
        console.log(BASE_URL)
        const newSocket = io(`${BASE_URL}`, {
            transports: ['websocket'],
            auth: {userId: userId ?? +new Date()}
        });
        newSocket.on('balance', (pld) => {
            dispatch(updateBalance(pld.balance))
        })
        newSocket.on('notification', (pld) => {
            dispatch(updateAlert({
                isOpen: true,
                status: pld.type,
                message: pld.content.find((item: IMessage) => item.lang === i18n.language).message
            }))
        })
        setSocket(newSocket);
        return () => newSocket.close();
    }, [setSocket, userId]);

    // const toast = document.getElementsByClassName('Toastify')[0]
    // useEffect(()=>{
    //     console.log(toast)
    // },[toast])

    return (
        <>
            <PrettyToast/>
            <Suspense fallback={<AppLoader/>}>
                {!isLoading &&
                    <SocketProvider socket={socket}>
                        {
                            isMobileDevice ? <MobileApp/> : <DesktopApp/>
                        }
                    </SocketProvider>
                }
            </Suspense>
        </>
    )
}

export default Router;

import React, {useState} from 'react';
import {ChangePasswordInfo} from "../../components/profile/ChangePasswordWrapper";
import DesktopWrapper from "../../components/profile/DesktopWrapper";
import {ProfileHistoryInfo, ProfileHistoryResult} from "../../components/profile/ProfileHistoryWrapper";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import {useGetBetsMutation, useGetTransactionsMutation} from "../../redux/api/transactionApi";
import HistoryItem from "../../ui/HistoryItem";
import {getStorage} from "../../hooks/useStorage";
import {useSearchParams} from "react-router-dom";


const CashbackHistoryDesktop = (): JSX.Element => {
    const PERMISSIONS = getStorage('ACCESS.PROFILE_OPERATION_HISTORY.PERMISSIONS')
    const [getTransactions] = useGetTransactionsMutation();
    const {transactionsCount, transactions} = useTypedSelector((state) => {
        return {
            transactionsCount: state.transactionSlice.cashbackOperationsCount,
            transactions: state.transactionSlice.cashbackOperations
        }
    })

    const [rangesParams, setRangesParams] = useSearchParams()
    const callback = (props: { page?: number, action?: 'add' }) => getTransactions({
        range_start: Number(rangesParams.get('range_start')),
        range_end: Number(rangesParams.get('range_end')),
        type: 'cashback',
        page: props.page || undefined,
        action: props.action || undefined
    })

    return (
        <DesktopWrapper
            title={'PROFILE_CASHBACK_HISTORY'}
            count={transactionsCount}
            childrenInfo={<ProfileHistoryInfo action={callback}/>}
            childrenResult={<ProfileHistoryResult action={callback}
                                                  operationCount={transactionsCount}
                                                  operationArray={transactions}>
                {transactions.map((item, index) => (
                    <React.Fragment key={`operations-history-${index}-${item.createdAt}`}>
                        <HistoryItem amount={`${item.amount.toFixed(2)}`}
                                     date={item.createdAt} type={item.type}/>
                    </React.Fragment>
                ))}
            </ProfileHistoryResult>
            }
        />
    );
};

export default CashbackHistoryDesktop;

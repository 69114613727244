import {useRef, useEffect, useCallback} from 'react'
import throttle from 'lodash/throttle'
import {Record} from "immutable";

interface IWindowScrollPositionKey {
    y: string
    x: string
}

const windowScrollPositionKey: IWindowScrollPositionKey = {
    y: 'pageYOffset',
    x: 'pageXOffset'
}

const documentScrollPositionKey = {
    y: 'scrollTop',
    x: 'scrollLeft'
}
const getScrollPosition = (axis: keyof IWindowScrollPositionKey) =>
    // @ts-ignore
    window[windowScrollPositionKey[axis]] ||
    // @ts-ignore
    document.documentElement[documentScrollPositionKey[axis]] ||
    // @ts-ignore
    document.body[documentScrollPositionKey[axis]] ||
    0

export const ReactWindowScroller = ({
                                        // @ts-ignore
                                        children,
                                        throttleTime = 10,
                                        isGrid = false
                                    }) => {
    const ref = useRef()
    const outerRef = useRef()

    useEffect(() => {
        // console.log(ref, outerRef, 'test')
        const handleWindowScroll = throttle(() => {
            // @ts-ignore
            const {offsetTop = 0, offsetLeft = 0} = outerRef.current || {}
            // @ts-ignore
            const scrollTop = getScrollPosition('y') - offsetTop
            // @ts-ignore
            const scrollLeft = getScrollPosition('x') - offsetLeft
            // @ts-ignore
            if (isGrid) ref.current && ref.current.scrollTo({scrollLeft, scrollTop})
            // console.log(scrollLeft, scrollTop, 'test2')
            // @ts-ignore
            if (!isGrid) ref.current && ref.current.scrollTo(scrollTop)
        }, throttleTime)

        window.addEventListener('scroll', handleWindowScroll)
        return () => {
            handleWindowScroll.cancel()
            window.removeEventListener('scroll', handleWindowScroll)
        }
    }, [isGrid])

    const onScroll = useCallback(
        // @ts-ignore
        ({scrollLeft, scrollTop, scrollOffset, scrollUpdateWasRequested}) => {
            if (!scrollUpdateWasRequested) return
            const top = getScrollPosition('y')
            const left = getScrollPosition('x')
            // console.log('test',scrollTop, top, left)
            // @ts-ignore
            const {offsetTop = 0, offsetLeft = 0} = outerRef.current || {}
            // @ts-ignore
            scrollOffset += Math.min(top, offsetTop)
            // @ts-ignore
            scrollTop += Math.min(top, offsetTop)
            // @ts-ignore
            scrollLeft += Math.min(left, offsetLeft)
            if (!isGrid && scrollOffset !== top) window.scrollTo(0, scrollOffset)
            if (isGrid && (scrollTop !== top || scrollLeft !== left)) {
                window.scrollTo(scrollLeft, scrollTop)
            }
        },
        [isGrid]
    )

    return children({
        ref,
        outerRef,
        style: {
            width: isGrid ? 'auto' : '100%',
            height: '100%',
            display: 'inline-block'
        },
        onScroll
    })
}
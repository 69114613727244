import React, {useEffect, useRef} from 'react';
import Portal from "services/PortalService";
import useOutsideClick from "hooks/useOutsideClick";
import styled, {css} from "styled-components";

type ModalProps = {
    onClose: () => void,
    open: boolean,
    children: React.ReactNode,
    isFullScreen?: boolean,
    extra?: string,
}

const Modal = (props: ModalProps) => {
    const {
        open,
        onClose,
        children,
        isFullScreen = false,
        extra = ``
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        onClose();
    })

    return (
        <>
            {open && <Portal id="modal-root">
                <ModalWrapper extra={extra}>
                    <Container isFullScreen={isFullScreen} ref={ref}>
                        {children}
                    </Container>
                </ModalWrapper>
            </Portal>}
        </>
    );
};

export default Modal;

const Container = styled.div<{ isFullScreen: boolean }>`
  ${({isFullScreen}) =>
          !isFullScreen ?
                  css`
                    margin: 0;
                    //position: relative;
                    display: flex;
                    flex-direction: column;
                    height: min-content;
                    max-height: 95vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                  `
                  : css`
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                  `}
`

const ModalWrapper = styled.div<{ extra: string }>`
  padding: 0;
  z-index: 9000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(95, 99, 104, 0.4);

  height: 100vh;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  ${({extra}) => extra}
`



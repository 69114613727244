import axios from "axios";
import {store} from "redux/store";
import {t} from "i18next";
import {useTypedSelector} from "../hooks/useTypedRedux";
import {toast} from "react-toastify";

const {dispatch} = store;

interface IOpenGame {
    providerId: string,
    isAuth: boolean,
    gameId: string,
}

const openGame = ({providerId, gameId, isAuth}: IOpenGame) => {
    if (isAuth) {
        window.location.href = `/game/${providerId}/${gameId}`;
    } else {
        //@ts-ignore
        toast.error(t('messages.alert.please_login'))
    }

};

export default openGame;

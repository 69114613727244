import React from 'react';
import {useTranslation} from 'react-i18next';
import {getStorage} from 'hooks/useStorage';
import ListMenu from "ui/material-kit/ListMenu";

type LanguageControllerProps = {
    isDesktop?: boolean;
    callBack?: () => void;
}

const LanguageController = (props: LanguageControllerProps) => {
    const {callBack} = props;
    const {i18n} = useTranslation();
    const languages = getStorage("APP_OTHER.LANGUAGES");
    let languagesArr: any = Object.values(languages).filter((item: any) => item.VISIBLE);


    const languageData: { title: string, key: string }[] = languagesArr.map((item: any) => ({
        title: item.TITLE,
        key: item.KEY,
    }));

    return (
        <>
            {languageData.filter((lang) => lang.key !== i18n.language)
                .map((item) => (
                    <React.Fragment key={`drawer-list-language-${item.key}`}>
                        <ListMenu title={item.title}
                                  onClick={() => {
                                      callBack && callBack();
                                      i18n.changeLanguage(item.key)
                                  }}/>
                    </React.Fragment>
                ))
            }

        </>
    )
};

export default LanguageController;


import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {useTranslation} from 'react-i18next';
import {getStorage} from "hooks/useStorage";
import DesktopCard from "../../ui/cards/DesktopCard";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import {device} from "../../constants/deviceSizes";
import {toast} from "react-toastify";
import useMediaQuery from "../../hooks/useMediaQuery";
import {useCustomToast} from "../../hooks/useCustomToast";
import {userIdSelector, userSelector} from "../../redux/selectors/userSelector";
import {isMobileSelector} from "../../redux/selectors/appSelector";


type IPromotionsCardsProps = {
    isAuth?: boolean,

}

const PromotionsCards = (props: IPromotionsCardsProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const navbar: {
        KEY: string,
        AUTH: boolean,
        VISIBLE: boolean,
        COLOR?: string,
        PATH: string,
        DESKTOP_PATH: string,
        ICON_PATH: string,
    }[] = getStorage("APP_OTHER.NAVBAR");

    const user = useTypedSelector(userSelector)
    const isMobile = useTypedSelector(isMobileSelector)

    const callToast = useCustomToast({text: t('messages.alert.please_login'), type: "error"});
    const mobileL = useMediaQuery(device.mobileL)

    let data = Object.values(navbar).filter(item => item.VISIBLE)

    if (!mobileL)  data.pop();

    return (
        <Wrapper isMobile={isMobile}>
            {
                data.map((item, index) => (
                    <React.Fragment key={`promotion-card-${index}-${item.KEY}`}>
                        <DesktopCard
                            onClick={() => {
                                if (item.AUTH && !user) {
                                    callToast()
                                } else
                                    navigate(item.DESKTOP_PATH);
                                window.scrollTo(0, 0);
                            }}
                            color={item.COLOR}
                            title={t(`pages.main.cards.${item.KEY}`)} icon={item.ICON_PATH}/>
                    </React.Fragment>
                ))
            }
        </Wrapper>
    );
};

export default PromotionsCards;


const Wrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  width: 100%;

  > :nth-child(1) {
    div > span > img {

      height: 87px;
    }
  }

  > :nth-child(2) {
    div > span > img {
      margin-left: 0.85vw;

      height: 107px;
    }
  }

  > :nth-child(3) {
    div > span > img {

      height: 128px;
    }
  }

  > :nth-child(4) {
    div > span > img {

      height: 92px;
    }
  }

  > :nth-child(5) {
    div > span > img {

      height: 83px;
    }
  }

  > :nth-child(6) {
    div > span > img {

      height: 100px;
    }
  }

  > :nth-child(8) {
    div > span > img {

      height: 98px;
    }
  }


  ${({isMobile}) =>
          isMobile ?
                  css`
                    grid-auto-flow: row;
                    grid-gap: 6px;
                    grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
                    @media (${device.laptop}) {
                      grid-template-columns: repeat(4, 1fr);
                    }
                    @media (${device.mobileL}) {
                      grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
                    }
                  `
                  :
                  css`
                    grid-auto-flow: column;
                    grid-auto-columns: 1fr;
                    grid-template-rows: 1fr;
                    grid-gap: 12px;
                  `}
`

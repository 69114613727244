import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";
import FilterDateCustom from "../../ui/FilterDateCustom";
import { IBet, ICashBack, ITransaction } from "redux/types/transactionTypes";
import { endOfDay, startOfDay } from "date-fns";

interface IProfileHistoryInfoProps {
  action: (props: { action?: "add"; page?: number }) => void;
}

interface IProfileHistoryResultProps {
  operationArray: IBet[] | ITransaction[] | ICashBack[];
  operationCount: number;
  action: (props: { action?: "add"; page?: number }) => void;
  children?: React.ReactNode;
  isMobile?: boolean;
  isLoading?: boolean;
}

export const ProfileHistoryInfo = (props: IProfileHistoryInfoProps) => {
  const [rangesParams, setRangesParams] = useSearchParams();
  const [calendar, setCalendar] = useState({
    range_start: Number(
      startOfDay(
        +(
          rangesParams.get("range_start") ||
          new Date().setDate(new Date().getDate() - 7)
        )
      )
    ),
    range_end: Number(endOfDay(+(rangesParams.get("range_end") || new Date()))),
  });
  return (
    <>
      <FilterDateCustom
        date_start={new Date(calendar.range_start)}
        date_end={new Date(calendar.range_end)}
        onSave={(from: Date, to: Date) => {
          setCalendar({ range_start: +from, range_end: +to });
          props.action({});
        }}
      />
    </>
  );
};

export const ProfileHistoryResult = (props: IProfileHistoryResultProps) => {
  const { operationCount, operationArray, action, children, isLoading } = props;
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  useEffect(() => {
    setHasMoreItems(true);
  }, [operationCount]);

  const loadMore = () => {
    if (operationArray.length >= operationCount) {
      setHasMoreItems(false);
      return;
    }
    setHasMoreItems(true);
    let newPage = page + 1;
    setPage(newPage);
    action({
      action: "add",
      page: page + 1,
    });
  };

  return (
    <InfiniteScroll
      dataLength={props.operationArray.length}
      scrollableTarget={`scrollableDivOperations`}
      next={loadMore}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "15px",
        height: "100%",
        padding: "0 5px",
        overflow: "overlay",
        boxSizing: "border-box",
        gridAutoRows: "min-content",
        paddingBottom: 20,
      }}
      hasMore={hasMoreItems}
      loader={<></>}
    >
      {children}
    </InfiniteScroll>
  );
};

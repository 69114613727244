import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import Input from "./material-kit/Input";
import {ReactComponent as ArrowIcon} from "assets/images/header_drop-down-button_icon.svg";
import ArrowImage from "assets/images/header_drop-down-button_icon.svg";

import styled from "styled-components";
import {DayPicker, ClassNames} from 'react-day-picker';
import styling from 'react-day-picker/dist/style.module.css';
import Modal from "./material-kit/Modal";
import IconButton from "./material-kit/IconButton";
import {ReactComponent} from "*.svg";
import {ar, bs, de, enUS, es, faIR, fr, tr} from "date-fns/locale";
import {useTranslation} from "react-i18next";

type DatePickerMomentProps = {
    label?: string,
    value?: Date,
    minDate?: Date,
    maxDate?: Date,
    onChange: React.Dispatch<React.SetStateAction<Date | undefined>>
    id?: string
}


const locales = {
    de: de,
    fr: fr,
    tr: tr,
    en: enUS,
    sr: bs,
    ar: ar,
    fa: faIR,
    es: es,
};


const CustomDatePicker = ({label, value, minDate, maxDate, onChange, id}: DatePickerMomentProps): JSX.Element => {
    const [openPicker, setOpenPicker] = useState(false);
    const {i18n} = useTranslation();

    let classNames: ClassNames = {
        ...styling,
        day_selected: 'day-selected',
        day: 'day',
        head: 'head',
        caption_dropdowns: 'caption-dropdowns',
        dropdown_month: 'dropdown-month',
        caption_label: 'caption-label',
        dropdown: 'dropdown',
        day_today: 'day-today'

    };

    return (
        <>
            <div style={{width: "100%", cursor: "pointer"}} onClick={() => {
                setOpenPicker(!openPicker)
            }}>
                <Input
                    id={id} leftContent={`${label?.toUpperCase()}:`}
                    inputExtra={`text-align: right; cursor: pointer;`}
                    isReadOnly
                    rightIcon={{
                        icon: <ArrowIcon/>,
                        extra: `width: 16px; height: 16px`,
                        onClick: () => undefined
                    }}
                    value={`${format(value || new Date(), 'dd.MM.yyyy')}`}
                    onChange={(e) => {

                    }}
                />
            </div>
            <Modal isOpen={openPicker} withHeader={false} onClose={() => {
                setOpenPicker(false)
            }} style={{padding: '0'}}>
                <DatePickerContainer>
                    <StyledDatePicker dropImage={ArrowImage}
                                      fromYear={2021}
                                      toYear={+format(new Date(), "yyyy")}
                                      mode={"single"}
                                      classNames={classNames}
                                      defaultMonth={value ? new Date(value.getFullYear(), value.getMonth(), 1) : new Date()}
                                      disabled={{from: minDate, to: maxDate}}
                                      selected={value}
                                      onSelect={(date) => {
                                          onChange(date);
                                          setOpenPicker(false);
                                      }}
                                      captionLayout={"dropdown"}
                                      locale={locales[i18n.language as 'en'] ?? enUS}
                                      dir={i18n.dir()}

                    />
                </DatePickerContainer>

            </Modal>
        </>
    );
};

export default CustomDatePicker;

const DatePickerContainer = styled.div`
  background: ${({theme}) => theme.background.main};
  border-radius: 5px;
  //margin-top: 10px;
  padding: 16px 10px 30px 10px;
  //padding: 0 10px 30px 10px;
  box-sizing: border-box;
  color: black;
`

const StyledDatePicker = styled(DayPicker)<{ dropImage: string }>`

  table {
    direction: ltr;
  }

  .head {
    color: ${({theme}) => theme.primary.main};
  }

  & td {
    padding: 3px;
  }

  .caption-dropdowns {
    margin-bottom: 20px;
    gap: 15px;
  }

  .day {
    background: #eaeaea;
    width: 35px;
    height: 35px;
    border-radius: 20px;


    :hover:not([disabled]) {
      background-color: ${({theme}) => theme.secondary.disabled};
    }

    :focus:not([disabled]) {
      background: ${({theme}) => theme.primary.light};
      border: none
    }
  }

  .day-today {
    border: 2px solid ${({theme}) => theme.primary.main};

    :hover {
      background-color: ${({theme}) => theme.secondary.disabled};
    }
  }

  .dropdown {
    cursor: pointer;
    font-size: 16px;
    outline: none;
    border: none;
    height: 34px;
    color: ${({theme}) => theme.text.white};
    background-color: transparent;
    text-transform: capitalize;
    option {

      background: ${({theme}) => theme.secondary.main};
    }
  }

  .caption-dropdowns {
    display: flex;
  }

  .caption-label {
    display: none;
  }

  .day-selected {
    background: ${({theme}) => theme.primary.light};
  }

`

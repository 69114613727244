import {createSlice, Draft} from "@reduxjs/toolkit";
import {IGame, IGameState, IProviderGame} from "../types/gamesTypes";
import {OrderedMap} from "immutable";
import {gamesAPI} from "../api/gamesApi";

const initialState: IGameState = {
    providersList: {
        providers: [],
        providersTv: []
    },
    gameListCount: 0,
    categories: [],
    tvGames: OrderedMap(),
    tvGamesCount: 0,
    games: OrderedMap(),
    gameList: OrderedMap(),
    favoriteGames: []
}

const gamesSlice = createSlice({
    name: "games",
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder
            // matchPending
            .addMatcher(
                gamesAPI.endpoints.getGames.matchPending,
                (state, {payload: newGames}) => {
                    state.games = clearState()
                    state.tvGames = clearState()
                    state.gameList = clearState()
                    state.gameListCount = 0
                    state.tvGamesCount = 0
                })
            .addMatcher(
                gamesAPI.endpoints.getTvGames.matchPending,
                (state, action) => {
                    state.games = clearState()
                    state.tvGames = clearState()
                    state.gameList = clearState()
                    state.gameListCount = 0
                    state.tvGamesCount = 0
                })
            .addMatcher(
                gamesAPI.endpoints.getGameList.matchPending,
                (state, action) => {
                    state.games = clearState()
                    state.tvGames = clearState()
                    state.tvGamesCount = 0
                })
            .addMatcher(
                gamesAPI.endpoints.getTvGamesList.matchPending,
                (state, action) => {
                    state.games = clearState()
                    state.gameList = clearState()
                    state.gameListCount = 0
                })

            // matchFulfilled
            .addMatcher(
                gamesAPI.endpoints.getProviders.matchFulfilled,
                (state, {payload}) => {
                    state.providersList.providers = payload
                })
            .addMatcher(
                gamesAPI.endpoints.getProvidersTv.matchFulfilled,
                (state, {payload}) => {
                    state.providersList.providersTv = payload
                })
            .addMatcher(
                gamesAPI.endpoints.getCategories.matchFulfilled,
                (state, {payload}) => {
                    state.categories = payload
                })
            .addMatcher(
                gamesAPI.endpoints.getGames.matchFulfilled,
                (state, action) => {
                    state.games = newGamesMap(action.payload)
                })
            .addMatcher(
                gamesAPI.endpoints.getGameList.matchFulfilled,
                (state, action) => {
                    let type = action.meta.arg.originalArgs.data.type
                    if (type === 'favorite') {
                        state.favoriteGames = action.payload.Games
                        return;
                    }
                    if (!type) type = 'add'

                    state.gameList = newGameListMap(state.gameList, action.payload.Games, type)
                    state.gameListCount = action.payload.Count
                })
            .addMatcher(
                gamesAPI.endpoints.getTvGames.matchFulfilled,
                (state, action) => {
                    state.games = newGamesMap(action.payload)
                })
            .addMatcher(
                gamesAPI.endpoints.getTvGamesList.matchFulfilled,
                (state, action) => {
                    let type = action.meta.arg.originalArgs.data.type
                    if (!type) type = 'add'

                    state.tvGames = newGameListMap(state.tvGames, action.payload.Games, type)
                    state.tvGamesCount = action.payload.Count
                })
            .addMatcher(
                gamesAPI.endpoints.getProviderGames.matchFulfilled,
                (state, action) => {
                    let newGame = action.payload
                    let newMap = state.games;
                    let type = action.meta.arg.originalArgs.data.type

                    if (!type) type = 'add'

                    if (type === "update") {
                        let oldProviderGame = newMap.get(newGame.SectionId);
                        let newProviderGame = {...newGame, Games: [...oldProviderGame?.Games!, ...newGame.Games]};
                        newMap = newMap.set(newGame.SectionId, newProviderGame);
                    }

                    if (type === "add") {
                        let newProviderGame = {...newGame, Games: [...newGame.Games]};
                        newMap = newMap.set(newGame.SectionId, newProviderGame);
                    }

                    state.games = newMap
                })
            .addMatcher(
                gamesAPI.endpoints.toggleFavoriteGameSession.matchFulfilled,
                (state, action) => {
                    let newStateGame = action.payload
                    let type = action.meta.arg.originalArgs.type

                    if (type === 'gameList') {
                        let newMap = state.gameList;
                        let oldStateGame = newMap.get(newStateGame.Id)!
                        let updatedGame = {...oldStateGame, ...newStateGame}
                        newMap = newMap.set(newStateGame.Id, updatedGame)
                        state.gameList = newMap
                    } else {
                        let newMap = state.games;
                        let oldProvider = newMap.get(newStateGame.SectionId)!;
                        let oldGames = oldProvider?.Games.reduce((a: OrderedMap<string, IGame>, b) => a.set(b.Id, b), OrderedMap<string, IGame>());
                        let oldGame = oldGames.get(newStateGame.Id)!;

                        let newGames = oldGames.set(newStateGame.Id, {...oldGame, IsFavorite: newStateGame.IsFavorite});
                        let newProviderGame: IProviderGame = {
                            ...oldProvider,
                            Games: [
                                ...newGames.toArray().map(item => item[1])
                            ]
                        }

                        newMap = newMap.set(newStateGame.SectionId, newProviderGame)
                        state.games = newMap
                    }

                })
    })
})

export default gamesSlice.reducer


const clearState = () => {
    let newMap: any = OrderedMap();
    return newMap;
}

const newGamesMap = (newGames: IProviderGame[]) => {
    let newMap: any = OrderedMap();
    for (let i in newGames) {
        let newGame = newGames[i];
        newMap = newMap.set(newGame.SectionId, newGame);
    }
    return newMap;
}

const newGameListMap = (oldGameList: Map<string, Draft<IGame>>, newGameList: IGame[], type: string) => {
    let map = oldGameList;

    if (type === 'update') {
        let clearMap: any = OrderedMap();
        for (let i in newGameList) {
            clearMap = clearMap.set(newGameList[i].Id, newGameList[i]);
        }
        map = clearMap;
    }
    if (type === 'add') {
        for (let i in newGameList) {
            map = map.set(newGameList[i].Id, newGameList[i]);
        }
    }

    return map;
}
import React, {useEffect, useState} from 'react';
import {useCreateMondoSessionMutation} from 'redux/api/gamesApi';
import {useTypedSelector} from "hooks/useTypedRedux";
import styled from "styled-components";
import Loader from "../../ui/Loader/Loader";


type MondoSport = {
    type: string,
    isDesktop?: boolean;
}
const MondoSport = (props: MondoSport) => {
    const {isDesktop, type} = props;
    const {user} = useTypedSelector(state => {
        return {
            user: state.userSlice.user,
        }
    });
    const t = type === "live-sport" ? 'live' : 'prematch';
    const [CreateMondoSession, {isLoading}] = useCreateMondoSessionMutation();
    const [mondoUrl, setMondoUrl] = useState<string>('');
    const [IframeLoaded, setIframeLoaded] = useState(false);

    useEffect(() => {
        setIframeLoaded(false);
        CreateMondoSession({mode: isDesktop ? 'desktop' : 'mobile', t})
            .then(x => "data" in x ? x.data : x).then((data) => {
            if ('sessionUrl' in data) {
                if (!isDesktop) {
                    window.location.href = data.sessionUrl;
                } else {
                    setMondoUrl(data.sessionUrl);
                }
            }

        });
    }, [type, user?.id, CreateMondoSession, isDesktop]);

    return (
        <SportContainer>
            {(!IframeLoaded || isLoading) && (
                <LoaderWrapper>
                  <Loader width={300}/>
                </LoaderWrapper>
            )}
            <MondoIframe src={mondoUrl} onLoad={() => setIframeLoaded(true)} allowFullScreen />

        </SportContainer>
    );
};

export default MondoSport;

const MondoIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 400;
  left: 0;
`

const SportContainer = styled.section`
  width: 100%;
  height: calc(100vh - 160px);
  top: 160px;
`

const LoaderWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: calc(100% - 160px);
  z-index: 401;
  background-color: rgba(0, 0, 0, 0.59);
  display: flex;
  justify-content: center;
  align-items: center;
`

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTypedSelector} from 'hooks/useTypedRedux';
import {useTranslation} from 'react-i18next';
import {getStorage} from 'hooks/useStorage';
import {device} from "../../../constants/deviceSizes";
import {userSelector} from "../../../redux/selectors/userSelector";
import {isMobileSelector} from "../../../redux/selectors/appSelector";

const InfoContainer = (): JSX.Element => {
    const {t} = useTranslation();

    const user = useTypedSelector(userSelector)
    const isMobile = useTypedSelector(isMobileSelector)

    const currency = getStorage("APP_OTHER.CURRENCY.KEY");
    return (
        <Wrapper isMobile={isMobile}>
            <ContainerInfo style={{justifySelf: 'end'}} >
                <Title>
                    {t(`common.header.id`)}{': '}
                </Title>
                <SecondText>
                    {user?.id}
                </SecondText>
            </ContainerInfo>
            <ContainerInfo style={{justifySelf: 'start'}}>
                <Title>
                    {t(`common.header.balance`)}{': '}
                </Title>
                <SecondText>
                    {` ${user?.balance.toLocaleString()} `}{currency}
                </SecondText>
            </ContainerInfo>
        </Wrapper>
    );
};

export default React.memo(InfoContainer);

const Wrapper = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-row-gap: 5px;
  @media (${device.laptopL}) {
    grid-column-gap: 20px;
  }
  grid-column-gap: 80px;
  padding: ${({isMobile}) => isMobile ? '8px' : '0px'};
  font-size: ${({isMobile}) => isMobile ? '10px' : '24px'};
  grid-template-columns: ${({isMobile}) => isMobile ? '1fr' : '1fr 1fr'};
  grid-template-rows: ${({isMobile}) => isMobile ? '1fr 1fr' : '1fr'};
`

const ContainerInfo = styled.div<{ isMobile?: boolean }>`
  display: flex;
  white-space: pre;
  text-transform: uppercase;
`

const Title = styled.div`
  color: ${({theme}) => theme.primary.light}
`

const SecondText = styled.div`
`
import Main from "pages/Main";
import React, { useEffect, useMemo, useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import getCorrectRoutes from "./routing";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedRedux";
import HeaderMobile from "../components/wrapper/header";

import FooterMobile from "components/wrapper/footer/FooterMobile";
import { ITheme } from "../interfaces/ITheme";
import Sport from "../pages/Sport";
import StaticPage from "../pages/StaticPage";
import ScrollButton from "../components/scrollButton";
import { userIdSelector } from "../redux/selectors/userSelector";
import { pagesSelector } from "../redux/selectors/appSelector";
import { getStorage } from "../hooks/useStorage";
import { useTranslation } from "react-i18next";

const MobileApp = () => {
  const { i18n } = useTranslation();
  const userId = useTypedSelector(userIdSelector);
  const pages = useTypedSelector(pagesSelector);

  const { pathname } = useLocation();

  const [betBy, setBetBy] = useState<any>(null);
  const [isBetBy, setIsBetBy] = useState<boolean>(false);
  useEffect(() => {
    sessionStorage.setItem("lastUrl", pathname);
    let b = document.getElementById("betby");
    if (pathname !== "/sport" && pathname !== "/live-sport") {
      b!.hidden = true;
      setIsBetBy(false);
      if (betBy) {
        betBy.clean();
      }
    } else {
      setIsBetBy(true);
      b!.hidden = false;
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const header: boolean = useMemo(() => {
    return !["/game/"].some((item) => pathname.includes(item));
  }, [pathname]);

  const footer: boolean = useMemo(() => {
    return ["/games", "/tv-games"].some((item) => pathname.includes(item));
  }, [pathname]);

  const spSportRoutes = (
    <>
      <Route path={`/sport`} element={<Sport type={"prelive"} />} />
      <Route path={`/live-sport`} element={<Sport type={"live"} />} />
    </>
  );

  const betBySportRoutes = (
    <>
      <Route
        path={`/sport`}
        element={
          <Sport
            type={"prematch"}
            isDesktop
            setBetBy={setBetBy}
            betBy={betBy}
          />
        }
      />
      <Route
        path={`/live-sport`}
        element={
          <Sport type={"live"} isDesktop setBetBy={setBetBy} betBy={betBy} />
        }
      />
    </>
  );

  useEffect(() => {
    //console.log(pages)
  }, [pages]);

  const getRoutes = getCorrectRoutes(
    !!userId,
    ["SPORT", "LIVE_SPORT", "STATIC_PAGES"],
    false
  );
  return (
    <>
      <GlobalStyle direction={i18n.dir()} />
      {header && <HeaderMobile />}
      <MainContainer
        footer={footer}
        header={header}
        id={"mobile-main-container"}
      >
        <Routes>
          {getRoutes}

          {process.env.REACT_APP_INSTANCE !== "maxgold"
            ? betBySportRoutes
            : spSportRoutes}

          {pages
            .filter((item) => item.lang === i18n.language)
            .map((item, index) => (
              <React.Fragment key={`${index}-${item.id}`}>
                <Route
                  path={`/static/${item.url}`}
                  element={<StaticPage id={item.id} isMobile />}
                />
              </React.Fragment>
            ))}

          <Route path={`/`} element={<Main />} />
          <Route path={`/*`} element={<Navigate to={`/`} />} />
        </Routes>
      </MainContainer>
      {footer && (
        <>
          <FooterMobile />
          <ScrollButton />
        </>
      )}
    </>
  );
};

export default MobileApp;

const MainContainer = styled.div<{ footer: boolean; header: boolean }>`
  margin-top: ${({ header }) => (header ? " 44px" : "0px")};
  margin-bottom: ${({ footer }) => (footer ? "84px" : "0px")};
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

const GlobalStyle = createGlobalStyle<{
  theme: ITheme;
  direction: "rtl" | "ltr";
}>`
  body {
    background-color: ${(props) => props.theme.background.main};
    direction: ${(props) => props.direction || "ltr"};

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      height: 6px;
      background-color: ${(props) => props.theme.primary.light};
    }
  }
`;

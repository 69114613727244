import React, {useState} from 'react';
import {PersonalDataInfo} from "../../components/profile/PersonalDataWrapper";
import MobileWrapper from "../../components/profile/MobileWrapper";


const PersonalDataMobile = (): JSX.Element => {

    return (
        <MobileWrapper
            title={'PROFILE_PERSONAL_DATA'}
            childrenInfo={<PersonalDataInfo/>}
        />
    );
};

export default PersonalDataMobile;

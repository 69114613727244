import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ProfileWrapper from "./MobileWrapper";
import routingLinks from "../../constants/routingLinks";
import Input from "../../ui/material-kit/Input";
import useInput from "../../hooks/useInput";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import useValidate from "../../hooks/useValidate";
import useTestValidation from "../../hooks/useTestValidation";
import {useUpdateMyPasswordMutation} from "../../redux/api/userApi";
import Button from "../../ui/material-kit/Button";
import styled from "styled-components";
import {validateExpression} from "../../constants/helperValidation";

export const ChangePasswordInfo = (): JSX.Element => {
    const {t} = useTranslation();
    const route = routingLinks.find(item => item.key === 'PROFILE_PERSONAL_DATA')
    const [updateMyPassword] = useUpdateMyPasswordMutation();

    const fields = {
        password: t(`common.placeholder.old_password_field`),
        newPassword: t(`common.placeholder.new_password_field`),
        repeatPassword: t(`common.placeholder.repeat_password_field`),
        change: t(`pages.profile.change`),
        incorrect_passwords: t(`messages.errors.passwords_not_match`),
    }


    const password = useInput('')
    const newPassword = useInput('')
    const repeatPassword = useInput('')


    const checkValidate = useTestValidation({
        validateObject: {
            passwordValidate: useValidate(password.value, validateExpression.password),
            newPasswordValidate: useValidate(newPassword.value, validateExpression.password),
            repeatPasswordValidate: useValidate(repeatPassword.value, validateExpression.password, [{
                status: newPassword.value !== repeatPassword.value,
                message: fields.incorrect_passwords
            }], [newPassword.value]),
        },
        callback: () => updateMyPassword({
            data: {
                password: password.value,
                newPassword: newPassword.value,
                repeatPassword: repeatPassword.value,
            },
            toast: 'all'
        }),
        callbackSuccess: () => {
            checkValidate.clear()
        }
    });

    return (
        <>
            <StInput {...password} type={'password'}
                     placeholder={fields.password} {...checkValidate.validate['passwordValidate']}/>
            <StInput {...newPassword} type={'password'}
                     placeholder={fields.newPassword} {...checkValidate.validate['newPasswordValidate']}/>
            <StInput {...repeatPassword} type={'password'}
                     placeholder={fields.repeatPassword} {...checkValidate.validate['repeatPasswordValidate']}/>

            <StyledButton onClick={() => checkValidate.checkValidation()} id={'password-change'}>
                {fields.change}
            </StyledButton>
        </>
    )
}

const StyledButton = styled(Button)`
  width: fit-content;
  justify-self: center;
  font-size: 20px;
`

const StInput = styled(Input)`
  font-size: 10px;

  ::placeholder {
    font-size: 10px;
  }
`
import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQueryInterceptor } from "./commonApi";
import { ITopItem } from "redux/types/hallOfFame";

export const hallOfFameApi = createApi({
    reducerPath: 'hallOfFameApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({
        getHallOfFameInfo: build.mutation<ITopItem[], { month: string }>({
            query: (obj: Record<any, any>) => ({
                url: `/info/hall-of-fame`,
                method: 'GET',
                params: obj
            }),
        }),
        getMonth: build.mutation<string[], void>({
            query: () => ({
              url: `/info/hall-of-fame/months`,
              method: 'GET',
            }),
            transformResponse: (months: string[]) => {
              return months.sort((a, b) => a > b ? 1 : -1);
            }
          }), 
          
    })
});


export const {
    useGetHallOfFameInfoMutation,
    useGetMonthMutation
} = hallOfFameApi

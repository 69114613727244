import React, {ReactComponentElement, useEffect, useState} from "react";
import styled from "styled-components"
import Button from "./material-kit/Button";
import {NavLink} from "react-router-dom";

interface IModalProfileItem {
    title: string,
    icon?: ReactComponentElement<any>,
    navigate: () => void,
    to?: string
}

const ModalProfileItem = (props: IModalProfileItem): JSX.Element => {
    const {title, icon, navigate, to} = props;

    return (
        <Wrapper id={`profile_modal_${title}`} onClick={() => navigate()} color={'secondary'}>
            <Icon>{icon}</Icon>
            <Text isSelected={window.location.pathname === to}>{title}</Text>
        </Wrapper>
    )
}


export default ModalProfileItem;

const Wrapper = styled(Button)`
  height: 62px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background-color: ${({theme}) => theme.secondary.main};

  :active {
    background-color: ${({theme}) => theme.secondary.focused};
    color: ${({theme}) => theme.primary.main};
  }
`

const Icon = styled.div`
  padding: 0 8px 0 6px;
  width: 42px;
  max-height: 42px;
`

const Text = styled.div<{ isSelected: boolean }>`
  color: ${({isSelected, theme}) => isSelected ? theme.primary.main : 'unset'};
`
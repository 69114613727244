import {ReactComponent as ArrowIcon} from "assets/images/icons/arrow_icon.svg"
import {ReactComponent as LanguageIcon} from "assets/images/icons/language_icon.svg"
import {ReactComponent as HelpIcon} from "assets/images/drawer_help_icon.svg"
import {useTypedSelector} from "hooks/useTypedRedux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {TawkService} from "services/TawkService";
import ListMenu from "ui/material-kit/ListMenu";
import {getStorage} from "../../../hooks/useStorage";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {userIdSelector} from "../../../redux/selectors/userSelector";
import { Fragment } from "react";

type TypeDrawerContentProps = {
    onClose: () => void,
    setLanguage: (lang: boolean) => void,
}

const DrawerContent = (props: TypeDrawerContentProps): JSX.Element => {
    const {onClose, setLanguage} = props
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {pages, langs} = useTypedSelector(state => {
        return {
            pages: state.appSlice.pages,
            langs: state.appSlice.languages
        }
    })
    const isAuth = !!useTypedSelector(userIdSelector);
    const callToast = useCustomToast({text: t('messages.alert.please_login'), type: "error"})
    const languages = getStorage("APP_OTHER.LANGUAGES");
    const links = Object.values(getStorage("APP_OTHER.SIDEBAR"));
    const curLang = languages[i18n.language.toUpperCase()]?.TITLE || i18n.language;

    const fields = {
        help: t(`common.drawer.help`),
        promotions: t(`common.drawer.promotions`),
        language: t(`common.drawer.language`),
        hall_of_fame: t(`common.drawer.the_hall_of_fame`)
    }

    const handleClick = (func: () => void) => {
        func()
        onClose();
    }

    return (
        <>
            {links.map((item: any, index) => (
                <Fragment key={`routing-navbar-link-${index}`}>
                    <ListMenu title={`${t(`common.navbar.${item.LANG_KEY}`)}`} onClick={() => {
                        if (item.AUTH && !isAuth) {
                            callToast();
                            return;
                        }
                        navigate(item.PATH);
                        onClose();
                    }}
                    />
                </Fragment>
            ))}

            {
                      process.env.REACT_APP_INSTANCE !== "maxgold" && <Fragment key={`help-item`}>
                      <ListMenu title={`${fields.help}`} icon={<HelpIcon/>} onClick={() => handleClick(() => {
                          let body = document.getElementsByTagName("body");
                          body.item(0)!.style.overflow = "auto";
                          TawkService.toggleWidget();
                      })}
                      />
                  </Fragment>
            }

            <Fragment key={`promotions-item`}>
                <ListMenu title={fields.promotions}
                          onClick={() => handleClick(() => navigate('/promotions'))}/>
            </Fragment>

            {pages.filter(item => item.lang === i18n.language).map((item, index) => (
                <Fragment key={`drawer-list-${index}-${item.title}`}>
                    <ListMenu title={item.title}
                              onClick={() => handleClick(() => navigate(`/static/${item.url}`))}/>
                </Fragment>
            ))}

            <Fragment key={`drawer-list-language-switcher`}>
                <ListMenu title={<>
                    <LanguageIcon/>
                    <span>{curLang}</span>
                </>} icon={<ArrowIcon/>}
                          onClick={() => setLanguage(true)}/>
            </Fragment>

        </>
    );
};


export default DrawerContent;


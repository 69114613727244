import { AppSelector } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { ICategoryGame, IGame } from "../types/gamesTypes";

const categories: AppSelector<ICategoryGame[]> = (state) => state.gamesSlice.categories
const favoriteGames: AppSelector<IGame[]> = (state) => state.gamesSlice.favoriteGames

const gamesCount: AppSelector<number> = (state) => state.gamesSlice.gameListCount
const gamesArray: AppSelector<IGame[]> = (state) => state.gamesSlice.gameList.toArray().map(item => item[1])

const tvGamesCount: AppSelector<number> = (state) => state.gamesSlice.tvGamesCount
const tvGames: AppSelector<IGame[]> = (state) => state.gamesSlice.tvGames.toArray().map(item => item[1])

export const gamesSelector = createSelector(gamesArray, (gamesArray) => gamesArray)
export const categoriesSelector = createSelector(categories, (categories) => categories)
export const favoriteGamesSelector = createSelector(favoriteGames, (favoriteGames) => favoriteGames)

export const gamesPageSelector = createSelector(gamesCount, gamesArray, (countGames, data) => ({ countGames, data }))
export const tvGamesPageSelector = createSelector(tvGamesCount, tvGames, (countGames, data) => ({ countGames, data }))

import React, {useMemo, useState} from "react";
import DrawerContent from "../drawer-new/DrawerContent";
// import DrawerFooter from "./DrawerFooter";
import Drawer from "ui/material-kit/Drawer";
import styled from "styled-components";
import {Navigator} from "../drawer-new/Navigator";
import {getStorage} from "../../../hooks/useStorage";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {device} from "../../../constants/deviceSizes";
import LanguageController from "../drawer-new/LanguageController";

type CustomDrawerProps = {
    open: boolean;
    setClosed: () => void;
};

const CustomDrawer = (props: CustomDrawerProps): JSX.Element => {
    const {
        open,
        setClosed
    } = props;
    const logo = useMemo(() => {
        const PATH = getStorage("APP_SEO.LOGO_PATH");
        return require(`../../../${PATH}`)
    }, []);

    const onClose = () => {
        setLanguage(false);
        setClosed();
    }
    const {t, i18n} = useTranslation();
    const [isLanguage, setLanguage] = useState<boolean>(false);
    return (
        <Drawer open={open} zIndex={999999} isRight={i18n.dir() === 'rtl'} onClose={onClose} extraBackground={`top: 0`}
                extraContainer={`margin-top: 0px; height: 100%`}>
            <Navigator onClose={onClose} onBack={isLanguage ? (() => setLanguage(false)) : undefined}/>
            <ContainerDrawer>
                {!isLanguage && <img src={logo} style={{maxWidth: 311}} alt="logo-drawer"/>}
                    {
                        isLanguage ? <LanguageController callBack={() => setLanguage(false)}/> :
                            <DrawerContent setLanguage={setLanguage} onClose={onClose}/>
                    }
                {!isLanguage && <NavbarItemHallOfFame onClick={onClose}>
                    <Link to={'/the-hall-of-fame'}>
                        {t('pages.hall_of_fame.title')}
                    </Link>
                </NavbarItemHallOfFame>}
            </ContainerDrawer>
        </Drawer>
    );
};


export default React.memo(CustomDrawer);

const ContainerDrawer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
  background-color: ${({theme}) => theme.background.main};
  max-width: 440px;
  width: 85vw;
  @media (${device.mobileL}) {
    width: 100vw;
  }
`;

const NavbarItemHallOfFame = styled.div`
  margin-top: 43px;

  a {
    padding: 13px 16px;
    color: var(--black-000000, #000);
    text-transform: uppercase;
    border-radius: 5px;
    background: var(--gold-2, linear-gradient(-45deg, #D7B563 0%, #DCBD53 9.38%, #B2843C 26.04%, #F4E478 58.85%, #BB913D 78.13%, #CDA865 90.63%, #D7B563 100%));
  }
`

import { useRef, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

var windowScrollPositionKey = {
    y: 'pageYOffset',
    x: 'pageXOffset'
};
var documentScrollPositionKey = {
    y: 'scrollTop',
    x: 'scrollLeft'
};

var getScrollPosition = function getScrollPosition(axis: string) {
    // @ts-ignore
    return window[windowScrollPositionKey[axis]] || document.documentElement[documentScrollPositionKey[axis]] || document.body[documentScrollPositionKey[axis]] || 0;
};
// @ts-ignore
var ReactWindowScroller = function ReactWindowScroller(_ref) {
    var children = _ref.children,
        _ref$throttleTime = _ref.throttleTime,
        throttleTime = _ref$throttleTime === void 0 ? 10 : _ref$throttleTime,
        _ref$isGrid = _ref.isGrid,
        isGrid = _ref$isGrid === void 0 ? false : _ref$isGrid;
    var ref = useRef();
    var outerRef = useRef();
    useEffect(function () {
        var handleWindowScroll = throttle(function () {
            var _ref2 = outerRef.current || {},
                // @ts-ignore
                _ref2$offsetTop = _ref2.offsetTop,
                offsetTop = _ref2$offsetTop === void 0 ? 0 : _ref2$offsetTop,
                // @ts-ignore
                _ref2$offsetLeft = _ref2.offsetLeft,
                offsetLeft = _ref2$offsetLeft === void 0 ? 0 : _ref2$offsetLeft;
            // @ts-ignore
            var scrollTop = getScrollPosition('y') - offsetTop;
            // @ts-ignore
            var scrollLeft = getScrollPosition('x') - offsetLeft;
            // @ts-ignore
            if (isGrid) ref.current && ref.current.scrollTo({
                scrollLeft: scrollLeft,
                scrollTop: scrollTop
            });
            // @ts-ignore
            if (!isGrid) ref.current && ref.current.scrollTo(scrollTop);
        }, throttleTime);
        window.addEventListener('scroll', handleWindowScroll);
        return function () {
            handleWindowScroll.cancel();
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, [isGrid]);
    // @ts-ignore
    var onScroll = useCallback(function (_ref3) {
        var scrollLeft = _ref3.scrollLeft,
            scrollTop = _ref3.scrollTop,
            scrollOffset = _ref3.scrollOffset,
            scrollUpdateWasRequested = _ref3.scrollUpdateWasRequested;
        if (!scrollUpdateWasRequested) return;
        var top = getScrollPosition('y');
        var left = getScrollPosition('x');

        var _ref4 = outerRef.current || {},
            // @ts-ignore

            _ref4$offsetTop = _ref4.offsetTop,
            offsetTop = _ref4$offsetTop === void 0 ? 0 : _ref4$offsetTop,
            // @ts-ignore

            _ref4$offsetLeft = _ref4.offsetLeft,
            offsetLeft = _ref4$offsetLeft === void 0 ? 0 : _ref4$offsetLeft;
        // @ts-ignore
        scrollOffset += Math.min(top, offsetTop);
        // @ts-ignore
        scrollTop += Math.min(top, offsetTop);
        // @ts-ignore
        scrollLeft += Math.min(left, offsetLeft);
        if (!isGrid && scrollOffset !== top) window.scrollTo(0, scrollOffset);

        if (isGrid && (scrollTop !== top || scrollLeft !== left)) {
            window.scrollTo(scrollLeft, scrollTop);
        }
    }, [isGrid]);
    return children({
        ref: ref,
        outerRef: outerRef,
        style: {
            width: isGrid ? 'auto' : '100%',
            height: '100%',
            display: 'inline-block',
        },
        onScroll: onScroll
    });
};

export { ReactWindowScroller };
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useGetGameListMutation} from "../../redux/api/gamesApi";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import useDimensions from "react-cool-dimensions";

import InfiniteScroll from "react-infinite-scroll-component";
import {IGame} from "../../redux/types/gamesTypes";
import GameCard from "../../ui/GameCard";

interface IGamesPageContainer {
    value: {
        game?: string,
        provider?: string
        category?: string
    }
}

const MemoGameCard = React.memo(({item, minHeight}: { item: IGame, minHeight: number }) => {
    return (
        <Game style={{height: minHeight}}>
            <GameCard isTv={false} game={item} style={{minHeight: minHeight / 2}} isLazy={true}/>
        </Game>
    )
})


const GamesVirtualListContainerDesktop = (props: IGamesPageContainer) => {
    const {game, provider, category} = props.value;
    const [getGameList, {isLoading}] = useGetGameListMutation();
    const {data, countGames, user} = useTypedSelector((state) => {
        return {
            user: state.userSlice.user,
            data: state.gamesSlice.gameList.toArray().map(item => item[1]).slice(22, 1000),
            countGames: state.gamesSlice.gameListCount - 22
        }
    })

    const [page, setPage] = useState(2)

    useEffect(() => {
        setPage(2);
        getGameList({
            data:
                {
                    type: 'update',
                    page: 1,
                    title: game,
                    provider,
                    category,
                    size: 23
                }
        })
    }, [game, provider])

    const [hasMoreItems, setHasMoreItems] = useState(true);

    const loadMore = useCallback(() => {
        if (data.length >= countGames) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getGameList({
            data: {
                page,
                title: game,
                provider,
                category,
                size: 20
            }
        })
    }, [countGames, data.length, game, getGameList, page, provider]);
    const {observe, width} = useDimensions({});
    const params = useMemo(() => {
        return width / 7
    }, [width])

    const stylesInfinite = useMemo(() => {
        return {
            display: "grid",
            gridTemplateColumns: 'repeat(7,1fr)',
            gridGap: '12px',
            height: '100%',
            overflow: 'hidden',
            gridAutoRows: 'min-content'
        }
    }, [])

    const loader = useMemo(() => <></>, [])

    return (
        <Wrapper ref={observe} id={`scrollableTvGames`}>
            <InfiniteScroll
                scrollableTarget={`main-wrapper`}
                dataLength={data.length}
                next={loadMore}
                style={stylesInfinite}
                hasMore={hasMoreItems && data.length !== 0}
                loader={loader}
            >
                {data.map((item, index) =>
                    <React.Fragment key={`game-${index}`}>
                        <MemoGameCard item={item} minHeight={params}/>
                    </React.Fragment>)}
            </InfiniteScroll>
        </Wrapper>
    );
};

export default GamesVirtualListContainerDesktop;

const Wrapper = styled.div`
  max-width: calc(100vw - 60px);
`

const Game = styled.div`
  box-sizing: border-box;
`

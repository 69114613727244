export default <T = any>(prevProps: T, nextProps: T, keys?: string[]): boolean => {

    const replacer = (key: string, value: any) => {
        return keys?.some(item => item === key)
            ? undefined
            : value
    }

    return !keys
        ? JSON.stringify(prevProps) === JSON.stringify(nextProps)

        : JSON.stringify(prevProps, (key, value) => replacer(key, value))
        === JSON.stringify(nextProps, (key, value) => replacer(key, value))
}
import React, {useCallback, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import {
    selectHallOfFame,
    selectMonth,
} from "../../redux/selectors/hallOfFameSelectors";
import {
    useGetHallOfFameInfoMutation,
    useGetMonthMutation,
} from "redux/api/hallOfFameApi";
import formatDate from "helpers/formatDate";
import BackgroundIntro from "../../assets/images/bg_hall_of_fame.png";
import BackgroundItem from "../../assets/images/bg_hall_of_fame_item.png";
import {useCustomToast} from "hooks/useCustomToast";
import {ContainerHallOfFame} from "ui/containers";
import {
    CircleImageStyles,
    ContentItemStyles,
    IntroInfoStyles,
    IntroWrapperStyles,
    ItemGameStyles,
    ItemListOfWinnersStyles,
    ItemNameStyles,
    ItemNumberStyles,
    ItemValueStyles,
    ListOfWinnersStyles,
    MonthItemStyles,
    MonthStyles,
    MonthsListStyles,
    NicknameStyles,
    TitlePageStyles,
} from "./HallOfFameComponents";
import {useNavigate} from "react-router-dom";

const List = React.memo(() => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const infoHall = useTypedSelector(selectHallOfFame);
    const user = useTypedSelector((state) => state.userSlice.user);

    const callToast = useCustomToast({
        text: t("messages.alert.please_login"),
        type: "error",
    });

    const handlePlayGame = useCallback((sectionID: string, id: string) => {
        if (!user) {
            callToast();
        } else {
            sessionStorage.setItem("previousPage", window.location.href);

            if (sectionID === "planetwinSport") {
                navigate("/sport");
            } else {
                navigate(`/game/${sectionID}/${id}`);
            }
        }
    }, []);

    const isRightDirection = i18n.dir() === 'rtl';

    return (
        <ListOfWinners>
            {infoHall.map((item, i: number) => (
                <ItemListOfWinners isRight={isRightDirection} key={item.login + i} bg={BackgroundItem}>
                    <ContentItem isRight={isRightDirection}>
                        <ItemNumber>{i + 1}</ItemNumber>
                        <ItemName>
                            <Nickname>{item.login}</Nickname>
                            <ItemGame
                                isRight={isRightDirection}
                                onClick={() =>
                                    handlePlayGame(item.game.SectionId, item.game.Id)
                                }
                            >
                                <p>{item.game.Name}</p>
                                <CircleImage src={item.game.Thumbnail}/>
                            </ItemGame>
                        </ItemName>
                        <ItemValue>
                            {item.value} {item.currency}
                        </ItemValue>
                    </ContentItem>
                </ItemListOfWinners>
            ))}
        </ListOfWinners>
    );
});

const HallOfFlame = () => {
    const infoMonth = useTypedSelector(selectMonth);
    const [showMonth, setShowMonth] = useState<boolean>(false);
    const [getInfoHall] = useGetHallOfFameInfoMutation();
    const [getMonth] = useGetMonthMutation();
    const {t, i18n} = useTranslation();
    const [thisMonth, setThisMonth] = useState<string>("");

    const handleChangeMonth = (month: string) => {
        if (month === thisMonth) {
            return;
        }
        setThisMonth(month);
        getInfoHall({month});
        setShowMonth(false);
    };

    const handleClickContainer = () => showMonth && setShowMonth(false);

    useEffect(() => {
        getMonth().then((pld: any) => {
            setThisMonth(pld.data[pld.data.length - 1]);
            getInfoHall({month: pld.data[pld.data.length - 1]});
        });
    }, []);

    return (
        <DesktopContainer onClick={handleClickContainer}>
            <IntroInfo bg={BackgroundIntro}>
                <IntroWrapper>
                    <TitlePage>{t("pages.hall_of_fame.title")}</TitlePage>
                    <Month>
                        <p onClick={() => setShowMonth(!showMonth)}>
                            {thisMonth
                                ? `${formatDate({
                                    date: new Date(thisMonth),
                                    dateFormat: "MMMM",
                                    lang: i18n.language,
                                })}`
                                : ""}
                        </p>
                        {showMonth && (
                            <MonthsList>
                                {infoMonth.map((month) => (
                                    <MonthItem
                                        key={month}
                                        onClick={() => handleChangeMonth(month)}
                                    >
                                        {`${formatDate({
                                            date: new Date(month),
                                            dateFormat: "MMMM",
                                            lang: i18n.language,
                                        })}`}
                                    </MonthItem>
                                ))}
                            </MonthsList>
                        )}
                    </Month>
                </IntroWrapper>
            </IntroInfo>
            <List/>
        </DesktopContainer>
    );
};

export default HallOfFlame;

const DesktopContainer = styled(ContainerHallOfFame)``;

const ListOfWinners = styled(ListOfWinnersStyles)``;

const ItemListOfWinners = styled(ItemListOfWinnersStyles)<{ isRight: boolean }>`
  height: 77px;
  background-size: cover;
  transform: ${({isRight}) => isRight ? 'scaleX(-1)' : 'scaleX(1)'};
`;

const ContentItem = styled(ContentItemStyles)<{ isRight: boolean }>`
  padding: 0 15px 0 31px;
  ${({isRight}) => isRight && css`
    transform: scaleX(-1);
    padding: 0 31px 0 15px;
  `};
`;

const ItemName = styled(ItemNameStyles)`
  width: 100%;
  margin: 0 260px 0 41px;
  font-size: ${({theme}) => theme.hall_of_fame.font_size_primary};
  justify-content: space-between;
  max-width: 585px;
`;

const Nickname = styled(NicknameStyles)`
  direction: ltr;
`;

const ItemGame = styled(ItemGameStyles)<{ isRight: boolean }>`
  max-width: 249px;
  margin-left: 25%;
  p {
    direction: ltr;
    font-size: 19px;
    margin-right: 33px;
    width: 145px;
    text-align: right;
    
    ${({isRight}) => isRight && css`
      margin-right: 0;
      margin-left: 33px;
      text-align: left;
  `};
  }
`;

const ItemNumber = styled(ItemNumberStyles)`
  width: 40px;
  margin: 5px 2px 0;
  font-weight: 800;
  font-size: 19px;
`;

const ItemValue = styled(ItemValueStyles)`
  font-size: ${({theme}) => theme.hall_of_fame.font_size_primary};
  width: 155px;
`;

const IntroInfo = styled(IntroInfoStyles)`
  background-size: cover;
  background-position: top center;
`;

const IntroWrapper = styled(IntroWrapperStyles)``;

const TitlePage = styled(TitlePageStyles)`
  font-size: ${({theme}) => theme.hall_of_fame.font_size_primary};
  text-align: center;
  text-transform: uppercase;
  width: max-content;
  position: relative;
  transform: inherit;
`;

const Month = styled(MonthStyles)`
  font-size: ${({theme}) => theme.hall_of_fame.font_size_primary};
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 200%);
`;

const MonthsList = styled(MonthsListStyles)`
  padding: 5px;
  gap: 3px;
  position: absolute;
  top: 35px;
  left: 0;
  transform: translateX(-37%);
  width: max-content;
  z-index: 3;
`;

const MonthItem = styled(MonthItemStyles)`
  width: 116px;
  margin: 0 0 3px;
  border-radius: 5px;
  font-size: 18px;
  line-height: 25px;

  &:hover {
    background: ${({theme}) => theme.hall_of_fame?.background_hover};
  }
`;

const CircleImage = styled(CircleImageStyles)`
  width: 60px;
  height: 60px;
`;

import React from "react";
import {formatForId} from "./formatString";

export interface ICalculateSelectorCoordinates {
    x: number
    y: number
    width: number
    maxHeight: number
}

export const CalculateSelectorCoordinates = (selectorRef: React.MutableRefObject<any>): ICalculateSelectorCoordinates => {
    if (selectorRef.current) {
        const COORDINATE = selectorRef.current.getBoundingClientRect()

        // console.log('COORDINATE = ', COORDINATE)
        // console.log('window.innerHeight = ', window.innerHeight)

        if (COORDINATE.y > window.innerHeight - COORDINATE.bottom &&
            window.innerHeight - COORDINATE.bottom < 170) {

            // console.log('result -1 = ',{
            //     x: COORDINATE.left,
            //     y: window.innerHeight - COORDINATE.y + 10,
            //     maxHeight: (COORDINATE.y - 30) * -1,
            //     width: COORDINATE.width
            // })

            // показать сверху
            return {
                x: COORDINATE.left,
                y: window.innerHeight - COORDINATE.y + 10,
                maxHeight: (COORDINATE.y - 30) * -1,
                width: COORDINATE.width
            }
        } else {

            // console.log('result +1 = ',{
            //     x: COORDINATE.left,
            //     y: COORDINATE.y + COORDINATE.height + 15,
            //     maxHeight: window.innerHeight - COORDINATE.y - COORDINATE.height - 60,
            //     width: COORDINATE.width
            // })

            // показать снизу
            return {
                x: COORDINATE.left,
                y: COORDINATE.y + COORDINATE.height + 15,
                maxHeight: window.innerHeight - COORDINATE.y - COORDINATE.height - 60,
                width: COORDINATE.width
            }
        }
    } else {

        // по умолчанию
        return {
            x: 0,
            y: 0,
            maxHeight: 0,
            width: 0
        }
    }
}

export const setTitle = (value: string, index: number, placeholder?: string,) => {
    // Если длина текста больше, чем контейнер, то задать атрибут title
    const el = document.getElementById(`selector_${formatForId(placeholder || `${index}`)}_${formatForId(value)}`)
    return el ? el.scrollWidth > el.clientWidth ? value : '' : ''
}

